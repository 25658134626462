
export const Accueil = () => {
  return (
    <div className="container-sm">
      <ArticleInformation />
    </div>
  );
};

function ArticleInformation() {
  return (
    <div className="row mb-4">
      <b className="fs-5 col-sm-12">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;L'état civil est un système administratif qui enregistre et
        documente les événements cruciaux de la vie des individus, tels que les
        naissances, les mariages et les décès. C'est un pilier fondamental de
        toute société, permettant de garantir des droits et des responsabilités
        légaux, ainsi que de maintenir des archives précieuses pour la recherche
        généalogique et historique.
      </b>
    </div>
  );
}

// function TriePar() {
//   return (
//     <div className="row">
//       <div className="col-sm-3 mb-3">
//         Trier par
//         <select
//           className="form-select mb-2"
//           aria-label="Default select example"
//           defaultValue=""
//         >
//           <option value="">Sélection</option>
//           <option value="1">Prix croissant</option>
//           <option value="2">Prix décroissant</option>
//           <option value="3">Surface croissante</option>
//           <option value="4">Surface décroissante</option>
//         </select>
//       </div>
//     </div>
//   );
// }
