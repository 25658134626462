import "../../assets/dist/css/carousel.css";
// import Slide3 from "../../assets/img/slide-3.jpg";
// import Slide3 from "../../assets/img/Accueil.jpg";
import Slide3 from "../../assets/img/bord.jpg";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import "../style.css";

interface MenuProps {
  urlValue: string;
}
export const ImageMenu = () => {
  return (
    <div className="container-fluid vh-100 d-flex justify-content-center align-items-center">
      <img
        className="bd-placeholder-img"
        width="100%"
        height="100%"
        src={Slide3}
        alt="Your Image Alt Text"
      />
      <div className="carousel-caption text-end">
        <h1>Commune Urbaine de Mahajanga</h1>
        <p style={{ fontSize: "22px" }}>
          Mahajanga, la plus grande ville de toute la côte Ouest de Madagascar,
          est la capitale de la région Boeny. Elle compte actuellement environ
          244 000 habitants. Cette ville est caractérisée par une population
          jeune, avec plus de 29,33% de ses habitants âgés entre 15 et 30 ans,
          et elle affiche un taux de croissance démographique de 2,9%.
        </p>
        {/* <p>
          <a className="btn btn-lg btn-primary" href="#">
            Commande en ligne
          </a>
        </p> */}
      </div>
    </div>
  );
};

export const MenuBas: React.FC<MenuProps> = ({ urlValue }) => {
  const [isHovered, setIsHovered] = useState(false);

  const history = useHistory();

  const redirectToHome = () => {
    history.push("/");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" onClick={redirectToHome} href="#">
                  Accueil
                </a>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{
                    pathname: "/nouveauclient",
                    state: { urls: "adoptions-ajout" },
                  }}
                  className="nav-link"
                  aria-current="page"
                  id="adoption"
                >
                  Adoption
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{
                    pathname: "/nouveauclient",
                    state: { urls: "coutumes-ajout" },
                  }}
                  className="nav-link"
                  aria-current="page"
                  id="coutume"
                >
                  Coutume
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{
                    pathname: "/nouveauclient",
                    state: { urls: "changes-nom-ajout" },
                  }}
                  className="nav-link"
                  aria-current="page"
                  id="changement"
                >
                  Changement de nom
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{
                    pathname: "/nouveauclient",
                    state: { urls: "deces-ajout" },
                  }}
                  className="nav-link"
                  aria-current="page"
                  id="deces"
                >
                  Décès
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{
                    pathname: "/nouveauclient",
                    state: { urls: "legitimations-ajout" },
                  }}
                  className="nav-link"
                  aria-current="page"
                  id="legitimation"
                >
                  Légitimation
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{
                    pathname: "/nouveauclient",
                    state: { urls: "matrimonials-ajout" },
                  }}
                  className="nav-link"
                  aria-current="page"
                  id="matrimonial"
                >
                  Matrimonial
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Naissance
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-dark"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <NavLink
                      to={{
                        pathname: "/nouveauclient",
                        state: { urls: "naisses-ajout" },
                      }}
                      className="dropdown-item"
                      activeClassName="active"
                      id="naissance"
                    >
                      Acte de naissance
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={{
                        pathname: "/nouveauclient",
                        state: { urls: "bulletin-naissance-ajout" },
                      }}
                      className="dropdown-item"
                      activeClassName="active"
                    >
                      Bulletin de naissance
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={{
                        pathname: "/nouveauclient",
                        state: { urls: "extrait-naissance-ajout" },
                      }}
                      className="dropdown-item"
                      activeClassName="active"
                    >
                      Extrait de naissance
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{
                    pathname: "/nouveauclient",
                    state: { urls: "reconnaissances-ajout" },
                  }}
                  className="nav-link"
                  aria-current="page"
                  id="reconnu"
                >
                  Reconnaissance
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{
                    pathname: "/nouveauclient",
                    state: { urls: "rectifications-ajout" },
                  }}
                  className="nav-link"
                  aria-current="page"
                  id="rectification"
                >
                  Rectification
                </NavLink>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Statut matrimonial
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-dark"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <NavLink
                      to={{
                        pathname: "/nouveauclient",
                        state: { urls: "celibats-ajout" },
                      }}
                      className="dropdown-item"
                      activeClassName="active"
                      id="celibat"
                    >
                      Célibat
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={{
                        pathname: "/nouveauclient",
                        state: { urls: "mariages-ajout" },
                      }}
                      className="dropdown-item"
                      activeClassName="active"
                      id="mariages"
                    >
                      Mariage
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={{
                        pathname: "/nouveauclient",
                        state: { urls: "divorces-ajout" },
                      }}
                      className="dropdown-item"
                      activeClassName="active"
                    >
                      Divorce
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
