import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faEnvelope,
  faPhone,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
export const Footer = () => {
  return (
    <footer className="text-center text-lg-start bg-light text-muted">
      <section className="">
        <div className="container text-center text-md-start mt-5">
          <div className="row">
            <div className="col-md-6 col-lg-4 col-xl-6 mx-auto my-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <i className="fas fa-gem me-3"></i>Etat Civil
              </h6>
              <p>
                Les registres d'état civil fournissent des informations
                essentielles sur l'identité des personnes, y compris leur nom,
                leur date et lieu de naissance, leur filiation, leur statut
                matrimonial, et d'autres données pertinentes. 
              </p>
            </div>

            {/* <div className="col-md-3 col-lg-2 col-xl-2 mx-auto my-4">
              <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
              <p>
                <a href="#!" className="text-reset">
                  Pricing
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Settings
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Orders
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Help
                </a>
              </p>
            </div> */}

            <div className="col-md-3 col-lg-5 col-xl-4 mx-auto mb-md-0 my-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p>
                <FontAwesomeIcon icon={faHome} className="me-3" /> MAHAJANGA,
                401
              </p>
              <p>
                <FontAwesomeIcon icon={faEnvelope} className="me-3" />
                sec@mairie-mahajanga.mg
              </p>
              <p>
                <FontAwesomeIcon icon={faPhone} className="me-3" /> +261 34 14
                401 03 / +261 34 14 401 37
              </p>
            </div>
          </div>
        </div>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        © 2023 Copyright Commune Urbaine de Mahajanga: &nbsp;
        <a className="text-reset fw-bold" href="https://mairie-mahajanga.mg/">
          mairie-mahajanga.mg
        </a>
      </div>
    </footer>
  );
};
