import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { SiteClient } from "./component/siteClient/SiteClient";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AjouterDeces } from "./Pages/Deces";
import { AjouterNaissance } from "./Pages/Naissance";
import { ClientInfo } from "./Pages/ClientInfo";
import { useState } from "react";
import { AjouterMariage } from "./Pages/Mariage";
import { AjouterAdoption } from "./Pages/Adoption";
import { AjouterCoutume } from "./Pages/Coutume";
import { AjouterMatrimonial } from "./Pages/Matrimonial";
import { AjouterChangement } from "./Pages/ChangementNom";
import { AjouterLegitimation } from "./Pages/Legitimation";
import { AjouterDivorce } from "./Pages/Divorce";
import { AjouterBulletin } from "./Pages/Bulletin";
import { AjouterExtraitNaissance } from "./Pages/ExtraitNaissance";
import { AjouterCelibat } from "./Pages/Celibat";
import { AjouterReconnaissance } from "./Pages/Reconnaissance";
import { AjouterRectification } from "./Pages/Rectification";

function App() {
  const [isAllowed, setIsAllowed] = useState("");
  return (
    <div className="container-fluid">
      <Router>
        <Switch>
          <Route path="/" exact render={() => <SiteClient />} />
          {/* <Route
            path="/naissance/:idClient"
            render={(props) => (
              <AjouterNaissance idClient={props.match.params.idClient} />
            )}
          /> */}
          {/* <Route
            path="/deces/:idClient"
            render={(props) => (
              <AjouterDeces idClient={props.match.params.idClient} />
            )}
          /> */}
          <Route path="/deces-ajout" component={AjouterDeces} />
          <Route exact path="/nouveauclient" component={ClientInfo} />
          <Route path="/naisses-ajout" component={AjouterNaissance} />
          <Route path="/mariages-ajout" component={AjouterMariage} />
          <Route path="/adoptions-ajout" component={AjouterAdoption} />
          <Route path="/coutumes-ajout" component={AjouterCoutume} />
          <Route path="/matrimonials-ajout" component={AjouterMatrimonial} />
          <Route path="/changes-nom-ajout" component={AjouterChangement} />
          <Route path="/legitimations-ajout" component={AjouterLegitimation} />
          <Route path="/divorces-ajout" component={AjouterDivorce} />
          <Route path="/bulletin-naissance-ajout" component={AjouterBulletin} />
          <Route
            path="/extrait-naissance-ajout"
            component={AjouterExtraitNaissance}
          />
          <Route path="/celibats-ajout" component={AjouterCelibat} />
          <Route
            path="/reconnaissances-ajout"
            component={AjouterReconnaissance}
          />
          <Route
            path="/rectifications-ajout"
            component={AjouterRectification}
          />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
