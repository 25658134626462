import React, { useEffect, useState } from "react";
import { Navbar } from "../component/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { Modal } from "../component/Modal/Modal";

interface ClientIdProps {
  idClient: string;
}
const initialData = {
  id: "",
  nomEnfant: "",
  prenomEnfant: "",
  dateDeces: "",
  lieuDeces: "",
  numeroActe: "",
  nomPere: "",
  prenomPere: "",
  nomMere: "",
  prenomMere: "",
};
export const AjouterDeces: React.FC = () => {
  const location = useLocation<ClientIdProps>();
  const idClient = location.state?.idClient || "";

  const [data, setData] = useState(initialData);
  const [idDeces, setIdDeces] = useState(null);
  const [nbreDeces, setNbreDeces] = useState("");
  const [idRectification, setIdRectification] = useState("");
  const [nbreRectification, setNbreRectification] = useState("");
  const [prixRectificationSimple, setPrixRectificationSimple] = useState(0);
  const [prixRectificationExpress, setPrixRectificationExpress] = useState(0);
  const [dataNomEnfant, setDataNomEnfant] = useState("");
  const [dataPrenomEnfant, setDataPrenomEnfant] = useState("");
  const [dataService, setDataService] = useState("");
  const [idCommande, setIdCommande] = useState(null);
  const [montantSimpleMG, setMontantSimpleMG] = useState(0);
  const [montantExpressMG, setMontantExpressMG] = useState(0);
  const [montantSimpleFR, setMontantSimpleFR] = useState(0);
  const [montantExpressFR, setMontantExpressFR] = useState(0);
  const [langueCommande, setLangueCommande] = useState("");
  const [serviceCommande, setServiceCommande] = useState("");

  const [dataMontant, setDataMontant] = useState("");
  const [dataRef, setDataRef] = useState("");
  const [isModal, setIsModal] = useState(false);
  const history = useHistory();

  const [langueUtile, setLangueUtile] = useState("");
  const [selectService, setSelectService] = useState("");
  const [sigle, setSigle] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [dateDeces, setDateDeces] = useState("");
  const [lieuDeces, setLieuDeces] = useState("");
  const [numAct, setNumAct] = useState("");
  const [status, setStatus] = useState("Encours");

  const [nomCompletPapa, setNomCompletPapa] = useState("");
  const [nomPapa, setNomPapa] = useState("");
  const [prenomPapa, setPrenomPapa] = useState("");
  const [nomCompletMama, setNomCompletMama] = useState("");
  const [nomMama, setNomMama] = useState("");
  const [prenomMama, setPrenomMama] = useState("");
  const [existe, setExiste] = useState(0);
  const [isSubmited, setIsSubmited] = useState(false);
  const [nombreCommande, setNombreCommande] = useState<number>(1);
  const [nombreRectificat, setNombreRectificat] = useState<number>(1);

  // Checkbox state
  const [displayRectificat, setDisplayRectificat] = useState(0);
  const [dateRectificat, setDateRectificat] = useState("");
  const [acteRectificat, setActeRecticat] = useState("Décès");
  const [dataFructueux, setDataFructueux] = useState(0);
  const [seconde, setSeconde] = useState(60);
  const [minute, setMinute] = useState(2);

  const urlProd = "https://commande.mairie-mahajanga.mg/api";
  // const urlProd = "http://127.0.0.1:8000/api";

  const today = new Date().toISOString().split("T")[0];
  let timer: NodeJS.Timeout;

  useEffect(() => {
    if ((seconde > 0 || minute > 0) && idCommande != "" && idCommande != null) {
      timer = setInterval(() => {
        if (seconde > 0) {
          setSeconde(seconde - 1);
        } else if (minute > 0) {
          setMinute(minute - 1);
          setSeconde(59);
        } else {
          clearInterval(timer); // Stop the timer when both minute and seconde reach 0.
        }
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [seconde, minute, idCommande]);

  function generateRandomNumber() {
    const min = 1000000;
    const max = 9999999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber;
  }

  //Navigation vers commande
  const navigateToFinish = () => {
    // Vous pouvez vérifier ici si le formulaire est valide avant de passer à la commande
    if (
      nom.trim() === "" ||
      langueUtile.trim() === "" ||
      selectService.trim() === "" ||
      dateDeces.trim() === "" ||
      lieuDeces.trim() === ""
    ) {
      // Affichez un message d'erreur ou effectuez toute autre action nécessaire
      alert("Veuillez remplir tous les champs requis.");
      return;
    }
  };

  //Separation nom et prénom
  const separerNomPrenom = (nomComplet: any, setNom: any, setPrenom: any) => {
    //Efface tous les espaces
    const nomPrenom = nomComplet.trim();
    // Diviser la chaîne en utilisant l'espace
    const parties = nomPrenom.split(" ");
    if (parties.length >= 1) {
      const nom = parties[0];
      const prenoms = parties.slice(1).join(" "); // Le reste de la chaîne est considéré comme les prénoms
      setNom(nom);
      setPrenom(prenoms);
      // Retourner un objet avec le nom et les prénoms
    } else {
      setNom("");
      setPrenom("");
    }
  };

  //fonction Hide and Show

  const showHideRectificat = () => {
    setDisplayRectificat((prev) => (prev === 1 ? 0 : 1));
  };
  // Appel de la fonction pour obtenir un numéro aléatoire
  const randomReference = generateRandomNumber();

  //Nom
  const handleChangeNom = (e: any) => {
    if (/^[a-zA-Z0-9]+$/.test(e.target.value) || e.target.value === "") {
      setNom(e.target.value);
      // nomInserer = e.target.value;
      data.nomEnfant = e.target.value;
    }
  };

  //Prénom
  const handleChangePrenom = (e: any) => {
    if (/^[a-zA-Z0-9-" "]+$/.test(e.target.value) || e.target.value === "") {
      setPrenom(e.target.value);
      data.prenomEnfant = e.target.value;
    }
  };

  //Lieu de Décès
  const handleChangeLieu = (e: any) => {
    if (/^[a-zA-Z0-9-" "]+$/.test(e.target.value) || e.target.value === "") {
      setLieuDeces(e.target.value);
    }
  };

  //Date de Décès
  const handleChangeDate = (e: any) => {
    setDateDeces(e.target.value);
    data.dateDeces = e.target.value;
  };

  //Numéro de l'acte
  const handleChangeNumActe = (e: any) => {
    if (/^[a-zA-Z0-9]+$/.test(e.target.value) || e.target.value === "") {
      setNumAct(e.target.value);
      data.numeroActe = e.target.value;
    }
  };

  //Nom de pere
  const handleChangeNomCompletPere = (e: any) => {
    if (/^[a-zA-Z0-9-" "]+$/.test(e.target.value) || e.target.value === "") {
      setNomCompletPapa(e.target.value);
      separerNomPrenom(e.target.value, setNomPapa, setPrenomPapa);
    }
  };

  //Nom de mere
  const handleChangeNomCompletMere = (e: any) => {
    if (/^[a-zA-Z0-9-" "]+$/.test(e.target.value) || e.target.value === "") {
      setNomCompletMama(e.target.value);
      separerNomPrenom(e.target.value, setNomMama, setPrenomMama);
    }
  };

  //Langue
  const handleChangeLangue = (e: any) => {
    setLangueUtile(e.target.value);
  };

  //Signe
  const handleChangeSigne = (e: any) => {
    setSigle(e.target.value);
  };

  //Type de service
  const handleChangeService = (e: any) => {
    setSelectService(e.target.value);
  };

  //Date rectification
  const handleChangeDateRectificat = (e: any) => {
    setDateRectificat(e.target.value);
  };
  //Hide Modal
  const toggleModal = () => setIsModal(!isModal);

  //Nombre commande
  const handleChangeNbreCommande = (e: any) => {
    const valeurs = e.target.valueAsNumber;
    setNombreCommande(valeurs);
    // Vérifiez que la valeur est un nombre
    if (!isNaN(valeurs)) {
      setNombreCommande(parseInt(valeurs, 10));
    }
  };

  //Nombre rectificat
  const handleChangeNbreRectificat = (e: any) => {
    const valeur = e.target.valueAsNumber;
    setNombreRectificat(valeur);
    if (!isNaN(valeur)) {
      setNombreRectificat(parseInt(valeur, 10));
    }
  };

  //Enregistrement Décès
  const handleClick = async () => {
    const formData = new FormData();
    formData.append("langue", langueUtile);
    formData.append("type_service", selectService);
    formData.append("sigle", sigle);
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("date_deces", dateDeces);
    formData.append("lieu_deces", lieuDeces);
    formData.append("num_acte", numAct);
    formData.append("nom_pere", nomPapa);
    formData.append("prenom_pere", prenomPapa);
    formData.append("nom_mere", nomMama);
    formData.append("prenom_mere", prenomMama);
    formData.append("nbre_com", nombreCommande.toString());

    // const donne = Object.fromEntries(formData);
    // console.log("DATA", donne);application/json; charset=UTF-8, multipart/form-data
    try {
      const response = await axios.post(urlProd + "/deces/add", formData, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      setIsSubmited(true);

      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);

      // Accéder à l'ID de la donnée insérée si disponible et le stocker dans l'état
      if (response.data.data && response.data.data.id) {
        setMontantSimpleMG(response.data.data.montantSimpleMG);
        setMontantExpressMG(response.data.data.montantExpressMG);
        setMontantSimpleFR(response.data.data.montantSimpleFR);
        setMontantExpressFR(response.data.data.montantExpressFR);
        setLangueCommande(response.data.data.langue);
        setServiceCommande(response.data.data.type_service);
        setIdDeces(response.data.data.id);
        setNbreDeces(response.data.data.nbre_com);
        setDataNomEnfant(response.data.data.nom);
        setDataPrenomEnfant(response.data.data.prenom);
        setDataService(response.data.data.type_service);
        // setDataNumActEnfant(acteNaiss);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //save Rectification
  const handleAddRectifiaction = async () => {
    const formData = new FormData();
    formData.append("type_service", selectService);
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("rectificat_acte", acteRectificat);
    formData.append("date_acte", dateRectificat);
    formData.append("nbre_com", nombreRectificat.toString());

    try {
      const response = await axios.post(
        urlProd + "/rectifications/add",
        formData,
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      setIsSubmited(true);

      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);
      // Accéder à l'ID de la donnée insérée si disponible et le stocker dans l'état
      if (response.data.data && response.data.data.id) {
        setIdRectification(response.data.data.id);
        setNbreRectification(response.data.data.nbre_com);
        setPrixRectificationSimple(response.data.data.montantSimple);
        setPrixRectificationExpress(response.data.data.montantExpress);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //Commande client
  const handleClickCommande = async () => {
    const sommeMontant = sommeCommande();
    const formData = new FormData();

    formData.append("date_commande", today);
    formData.append("ref_billet", randomReference.toString());
    formData.append("status", status);
    formData.append("fructueux", existe.toString());
    if (sommeMontant != undefined) {
      formData.append("montant", sommeMontant.toString());
    }
    formData.append("id_Client", idClient);

    if (idDeces != null && idDeces != "") {
      formData.append("id_Mort", idDeces);
    }

    if (idRectification != null && idRectification != "") {
      formData.append("id_Rectification", idRectification);
    }
    try {
      const response = await axios.post(urlProd + "/commandes/add", formData, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      setIsSubmited(true);
      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);

      if (response.data.data && response.data.data.id) {
        const idCom = response.data.data.id;
        setIdCommande(idCom);
        const montant = response.data.data.montant;
        const reference = response.data.data.ref_billet;
        const fructueux = response.data.data.fructueux;
        setDataMontant(montant);
        setDataRef(reference);
        setDataFructueux(fructueux);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //Somme de commande
  let somme = 0;
  const sommeCommande = () => {
    const montantDeces = calculeMontant(
      serviceCommande,
      langueCommande,
      montantSimpleMG,
      montantExpressMG,
      montantSimpleFR,
      montantExpressFR,
      nbreDeces
    );
    const montantRectification = calculeMontantDetail(
      serviceCommande,
      nbreRectification,
      prixRectificationSimple,
      prixRectificationExpress
    );
    // console.log("valeur", montantDeces, montantRectification);
    if (montantDeces != undefined) {
      somme += montantDeces + montantRectification;
    }
    return somme;
  };

  function calculeMontantDetail(
    service: any,
    nombreCommande: any,
    prixSimple: any,
    prixExpress: any
  ) {
    if (service == "Standard") {
      return nombreCommande * prixSimple;
    } else {
      return nombreCommande * prixExpress;
    }
  }
  function calculeMontant(
    service: any,
    langue: any,
    prixSimpleMG: any,
    prixExpressMG: any,
    prixSimpleFR: any,
    prixExpressFR: any,
    nombreCommande: any
  ) {
    if (service == "Standard" && langue == "Malagasy") {
      return nombreCommande * prixSimpleMG;
    } else if (service == "Standard" && langue == "Français") {
      return nombreCommande * prixSimpleFR;
    } else if (service == "Express" && langue == "Malagasy") {
      return nombreCommande * prixExpressMG;
    } else if (service == "Express" && langue == "Français") {
      return nombreCommande * prixExpressFR;
    }
  }

  useEffect(() => {
    if (isSubmited) {
      setData(initialData);
      setNom("");
      setPrenom("");
      setDateDeces("");
      setLieuDeces("");
      setNumAct("");
      setNomCompletPapa("");
      setNomCompletMama("");
      setSelectService("");
      setNombreCommande(0);
      setNombreRectificat(0);
      setDateRectificat("");
      setLangueUtile("");
      setIsSubmited(false);
    }
  }, [isSubmited]);

  //Retourne vide
  const handleVide = () => {
    setData(initialData);
    setNom("");
    setPrenom("");
    setDateDeces("");
    setLieuDeces("");
    setNumAct("");
    setSelectService("");
    setNomCompletPapa("");
    setNomCompletMama("");
    setDateRectificat("");
    setNombreCommande(1);
    setNombreRectificat(1);
    setLangueUtile("");
  };

  //Naviguer vers Accueil
  const NavigateAccueil = () => {
    history.push("/");
  };

  const handleSubmit = async (e: any) => {
    navigateToFinish();
    e.preventDefault();
    await handleClick();
    if (displayRectificat == 1) {
      await handleAddRectifiaction();
    }
  };

  useEffect(() => {
    if (
      (seconde == 0 || minute == 0) &&
      idCommande != 0 &&
      idCommande != null
    ) {
      const UtilisateurCopie = async () => {
        const val = await fetch(urlProd + `/commandes/${idCommande}`);
        const data = await val.json();
        // console.log("data", data);
        if (data.commande.fructueux != "" || data.commande.fructueux != null) {
          setDataFructueux(data.commande.fructueux);
          // console.log("aizaaaF", data.commande.fructueux);
        }
      };
      UtilisateurCopie();
    }
  }, [seconde, minute, idCommande]);

  return (
    <>
      <Navbar titreImg="/icon/logo.png" />
      <div className="pt-4 px-3">
        <h2 style={{ textAlign: "center", fontFamily: " Georgia, serif" }}>
          Demande de l'acte de Décès{" "}
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="row mt-2">
            <div className="row">
              <div className="col-sm">
                <RadioButton
                  nameLabel="Langue"
                  radioValue1="Malagasy"
                  handleChangeRadio1={handleChangeLangue}
                  radioLabel1="Malagasy"
                  radioValue2="Français"
                  handleChangeRadio2={handleChangeLangue}
                  radioLabel2="Français"
                />
              </div>
              <div className="col-sm">
                <RadioButtonTerme
                  nameLabel="Sigle"
                  radioValue1="DecesDelai"
                  handleChangeRadio1={handleChangeSigne}
                  radioLabel1="Décès/délai"
                  radioValue2="JugSuppl"
                  handleChangeRadio2={handleChangeSigne}
                  radioLabel2="Jug Suppl"
                  radioValue3="reconstit"
                  handleChangeRadio3={handleChangeSigne}
                  radioLabel3="Reconstitution"
                />
              </div>
            </div>
            <div className="col-sm-3 ">
              <Input
                label="Nom :"
                type="text"
                name="nom"
                value={nom}
                handleChange={handleChangeNom}
                required="required"
              />
              <Input
                label="Prénom(s) :"
                name="prenom"
                value={prenom}
                handleChange={handleChangePrenom}
              />

              <Input
                label="Nom et Prénom de père :"
                name="nom_pere"
                value={nomCompletPapa}
                handleChange={handleChangeNomCompletPere}
              />

              <Input
                label="Nom et Prénom de mère :"
                name="nom_mere"
                value={nomCompletMama}
                handleChange={handleChangeNomCompletMere}
              />
              <SelectService
                label="Service :"
                nameSelect="Choisissez votre service"
                name1="Service Standard"
                name2="Service Express"
                selectValue={selectService}
                handleSelected={handleChangeService}
              />
            </div>
            <div className="col-sm-3">
              <Input
                label="Lieu de décès :"
                name="lieu"
                value={lieuDeces}
                handleChange={handleChangeLieu}
              />
              {/* <SelectLieu
                label="Lieu de décès :"
                nameSelect="Choisissez votre lieu de naissance"
                name1="Mahajanga"
                name2="Mahabibo"
                selectValue={lieuDeces}
                handleSelected={handleChangeLieu}
              /> */}
              <Input
                label="Date de décès :"
                name="ddn"
                type="date"
                value={dateDeces}
                handleChange={handleChangeDate}
                required="required"
              />

              <Input
                label="Numéro de l'acte :"
                name="numAct"
                value={numAct}
                handleChange={handleChangeNumActe}
              />

              <Input
                label="Nombre de commande :"
                type="number"
                min={1}
                value={nombreCommande}
                handleChange={handleChangeNbreCommande}
                name="nbCommande"
                required="required"
              />
            </div>

            <>
              <div className="col-sm-6">
                <h4 className="center">Commandes complémentaires</h4>
                <div className="row">
                  <div className="col-sm-4">
                    <CheckBox
                      name="Rectification"
                      onClick={showHideRectificat}
                    />
                    <div
                      id="rectification"
                      style={{
                        display: displayRectificat === 1 ? "block" : "none",
                      }}
                    >
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Date de l'acte:"
                            type="date"
                            name="ddRectificat"
                            value={dateRectificat}
                            handleChange={handleChangeDateRectificat}
                          />
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Nombre de commande:"
                            type="number"
                            min={1}
                            value={nombreRectificat}
                            handleChange={handleChangeNbreRectificat}
                            name="nbRectificat"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-8"></div>
                </div>

                <div className="row">
                  <div className="col-sm">
                    <div className="row justify-content-center pt-2">
                      <button
                        type="button"
                        onClick={handleVide}
                        className="col-sm-6 btn btn-sm btn-primary mt-4 "
                        style={{
                          display:
                            idDeces == "" || idDeces == null ? "block" : "none",
                        }}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="row justify-content-center pt-2">
                      <button
                        type="submit"
                        // onClick={tempRestant}
                        className="col-sm-6 btn btn-sm btn-primary mt-4"
                        style={{
                          display:
                            idDeces == "" || idDeces == null ? "block" : "none",
                        }}
                      >
                        Passer à la commande
                      </button>
                    </div>
                  </div>
                </div>
                {idDeces != null && idDeces != "" ? (
                  <div className="row">
                    <div
                      className="col-sm-11 pt-2 mt-4 pb-2 "
                      style={{
                        borderRadius: "10px",
                        border: "1px solid #dee2e6 ",
                        // height: "170px",
                      }}
                    >
                      <h4
                        className="center"
                        style={{
                          fontFamily: " Georgia, serif",
                          textAlign: "center",
                        }}
                      >
                        Information sur votre commande :
                      </h4>
                      <div
                        style={{
                          fontFamily: " Georgia, serif",
                          marginLeft: "30px",
                        }}
                      >
                        <h6 className="col-sm-12 pt-2">
                          Nom : {dataNomEnfant}
                        </h6>
                        <h6 className="col-sm pt-2">
                          Prénom : {dataPrenomEnfant}
                        </h6>
                        <h6 className="col-sm pt-2">
                          Langue : {langueCommande}
                        </h6>
                        <h6 className="col-sm pt-2">
                          Type de service : {dataService}
                        </h6>
                      </div>
                      <div className="row">
                        <div className="col-sm"></div>
                        <div className="col-sm">
                          <div className="row justify-content-center pt-2">
                            <button
                              type="button"
                              onClick={() => {
                                handleClickCommande();
                                toggleModal();
                              }}
                              style={{
                                display:
                                  idClient == "" || idClient == null
                                    ? "none"
                                    : "block",
                              }}
                              className="col-sm-6 btn btn-sm btn-primary mt-4"
                            >
                              Commander
                            </button>
                            <Modal
                              title="Commande réussi !"
                              isOpen={isModal}
                              onClose={() => {
                                toggleModal();
                                NavigateAccueil();
                              }}
                            >
                              <hr />
                              <h5
                                style={{
                                  fontFamily: " Georgia, serif",
                                }}
                              >
                                Détails de votre commande :
                              </h5>

                              <div className="row">
                                <div className="col-sm-6">
                                  {" "}
                                  Acte de décès :<b> {nbreDeces} </b>
                                </div>
                                <div className="col-sm-6">
                                  {idRectification != null &&
                                  idRectification != "" ? (
                                    <>
                                      Rectification :{" "}
                                      <b> {nbreRectification} </b>
                                    </>
                                  ) : (
                                    <>
                                      Rectification : <b> 0 </b>
                                    </>
                                  )}
                                </div>
                              </div>

                              <div>
                                <h6 className="col-sm pt-2">
                                  Reférence : DEC-{dataRef}
                                  <br />
                                  Net à payer : {dataMontant} Ar
                                  <br />
                                </h6>
                              </div>
                              <hr />

                              <div className="row justify-content-center pt-2">
                                <div className="timer">
                                  <div className="container">
                                    <div className="timer_container">
                                      <div style={{ textAlign: "center" }}>
                                        <span
                                          style={{
                                            fontFamily: " Georgia, serif",
                                            fontSize: "14px",
                                          }}
                                        >
                                          Veuillez patienter après quelques
                                          minutes pour obtenir votre réponse ...
                                        </span>
                                        <br />
                                        <span style={{ fontSize: "20px" }}>
                                          Temps restant:
                                        </span>{" "}
                                        {minute}:{seconde}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {minute == 0 && seconde == 0 ? (
                                  <>
                                    {dataFructueux == 1 ? (
                                      <span style={{ textAlign: "center" }}>
                                        Passez prendre votre commande :
                                        {dataService == "Express" ? (
                                          <span>&nbsp;&nbsp;Après 1 heure</span>
                                        ) : (
                                          <span>
                                            {" "}
                                            &nbsp;&nbsp;Après 2 jours
                                          </span>
                                        )}
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          fontFamily: " Georgia, serif",
                                          fontSize: "16px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Veuillez contacter direct au responsable
                                        à l'hôtel de ville
                                      </span>
                                    )}
                                  </>
                                ) : null}
                              </div>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          </div>
        </form>
      </div>
    </>
  );
};

const CheckBox = ({ name, checked, onClick }: any) => {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        value=""
        checked={checked}
        onClick={onClick}
        id="flexCheckDefault"
      />
      <label className="form-check-label" htmlFor="flexCheckDefault">
        {name}
      </label>
    </div>
  );
};
const Input = ({
  label,
  type = "text",
  min = 1,
  name,
  handleChangefile,
  handleChange,
  accept,
  disable,
  value,
  style,
  checked,
  required,
}: any) => {
  return (
    <div className="form">
      <label htmlFor="" className="form-label">
        {label}
      </label>
      <>
        {type == "file" ? (
          <input
            accept="image/*"
            onChange={handleChangefile}
            name={name}
            type={type}
            min={min}
            className="form-control"
            required={required}
          />
        ) : (
          <input
            onChange={handleChange}
            name={name}
            type={type}
            value={value}
            disabled={disable}
            checked={checked}
            min={min}
            className="form-control"
            style={style}
            required={required}
          />
        )}
      </>
    </div>
  );
};

const SelectService = ({
  label,
  nameSelect,
  name,
  name1,
  name2,
  selectValue,
  handleSelected,
  id,
}: any) => {
  return (
    <>
      <label htmlFor="">{label}</label>
      <select
        className="form-select mb-2"
        aria-label="Default select example"
        name={name}
        value={selectValue}
        onChange={handleSelected}
        required
      >
        <option value="" key={id}>
          {nameSelect}
        </option>
        <option value="Standard">{name1}</option>
        <option value="Express">{name2}</option>
      </select>
    </>
  );
};
const SelectLieu = ({
  label,
  nameSelect,
  name,
  name1,
  name2,
  selectValue,
  handleSelected,
  id,
}: any) => {
  return (
    <>
      <label htmlFor="">{label}</label>
      <select
        className="form-select mb-2"
        aria-label="Default select example"
        name={name}
        value={selectValue}
        onChange={handleSelected}
        required
      >
        <option value="" key={id}>
          {nameSelect}
        </option>
        <option value="Mahajanga">{name1}</option>
        <option value="Mahabibo">{name2}</option>
      </select>
    </>
  );
};

function RadioButton({
  nameLabel,
  radioLabel1,
  radioLabel2,
  radioValue1,
  radioValue2,
  handleChangeRadio1,
  handleChangeRadio2,
}: any) {
  return (
    <div className="row">
      <div className="col-sm ">
        <label className="label text-gray-1000">{nameLabel} : &nbsp;</label>
        <div className="form-check-inline">
          <input
            type="radio"
            className="form-check-input"
            value={radioValue1}
            name="groupe2"
            id="malagasyRadio"
            onChange={handleChangeRadio1}
            required
          />
          <label className="form-check-label">{radioLabel1}</label>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div className="form-check-inline validate-input mb-2">
          <input
            type="radio"
            className="form-check-input"
            value={radioValue2}
            name="groupe2"
            id="francaisRadio"
            onChange={handleChangeRadio2}
          />
          <label className="form-check-label">{radioLabel2}</label>
        </div>
      </div>
    </div>
  );
}
function RadioButtonTerme({
  nameLabel,
  radioLabel1,
  radioLabel2,
  radioLabel3,
  radioValue1,
  radioValue2,
  radioValue3,
  handleChangeRadio1,
  handleChangeRadio2,
  handleChangeRadio3,
}: any) {
  return (
    <div className="row">
      <div className="col-sm">
        <label className="label text-gray-1000">{nameLabel} : &nbsp;</label>
        <div className="form-check-inline">
          <input
            type="radio"
            className="form-check-input"
            value={radioValue1}
            name="type[]"
            id="DecesDelai"
            onChange={handleChangeRadio1}
            required
          />
          <label className="form-check-label">{radioLabel1}</label>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div className="form-check-inline validate-input mb-2">
          <input
            type="radio"
            className="form-check-input"
            value={radioValue2}
            name="type[]"
            id="JugSuppl"
            onChange={handleChangeRadio2}
          />
          <label className="form-check-label">{radioLabel2}</label>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div className="form-check-inline validate-input mb-2">
          <input
            type="radio"
            className="form-check-input"
            value={radioValue3}
            name="type[]"
            id="JugSupp"
            onChange={handleChangeRadio3}
          />
          <label className="form-check-label">{radioLabel3}</label>
        </div>
      </div>
    </div>
  );
}
