import React, { useEffect, useState } from "react";
import { Navbar } from "../component/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { Modal } from "../component/Modal/Modal";
import { format } from "path";

interface ClientIdProps {
  idClient: string;
}
export const AjouterReconnaissance: React.FC = () => {
  const location = useLocation<ClientIdProps>();
  const idClient = location.state?.idClient || "";

  const [idReconnaissance, setIdReconnaissance] = useState(null);
  const [nbreReconnaissance, setNbreReconnaissance] = useState("");
  const [dataNomEnfant, setDataNomEnfant] = useState("");
  const [dataPrenomEnfant, setDataPrenomEnfant] = useState("");
  const [dataService, setDataService] = useState("");
  const [idCommande, setIdCommande] = useState(null);
  const [langueCommande, setLangueCommande] = useState("");
  const [serviceCommande, setServiceCommande] = useState("");
  const [montantSimpleMG, setMontantSimpleMG] = useState(0);
  const [montantExpressMG, setMontantExpressMG] = useState(0);
  const [montantSimpleFR, setMontantSimpleFR] = useState(0);
  const [montantExpressFR, setMontantExpressFR] = useState(0);

  const [dataMontant, setDataMontant] = useState("");
  const [dataRef, setDataRef] = useState("");
  const [isModal, setIsModal] = useState(false);
  const history = useHistory();
  const [selectService, setSelectService] = useState("");
  const [langueUtile, setLangueUtile] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [lieuReconnu, setLieuReconnu] = useState("");
  const [numAct, setNumAct] = useState("");
  const [status, setStatus] = useState("Encours");
  const [existe, setExiste] = useState(0);
  const [isSubmited, setIsSubmited] = useState(false);
  const [nombreCommande, setNombreCommande] = useState<number>(1);

  const [dataFructueux, setDataFructueux] = useState(0);
  const [seconde, setSeconde] = useState(60);
  const [minute, setMinute] = useState(2);

  const urlProd = "https://commande.mairie-mahajanga.mg/api";
  // const urlProd = "http://127.0.0.1:8000/api";

  const today = new Date().toISOString().split("T")[0];
  let timer: NodeJS.Timeout;

  useEffect(() => {
    if ((seconde > 0 || minute > 0) && idCommande != "" && idCommande != null) {
      timer = setInterval(() => {
        if (seconde > 0) {
          setSeconde(seconde - 1);
        } else if (minute > 0) {
          setMinute(minute - 1);
          setSeconde(59);
        } else {
          clearInterval(timer); // Stop the timer when both minute and seconde reach 0.
        }
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [seconde, minute, idCommande]);

  function generateRandomNumber() {
    const min = 1000000;
    const max = 9999999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber;
  }

  //Navigation vers commande
  const navigateToFinish = () => {
    // Vous pouvez vérifier ici si le formulaire est valide avant de passer à la commande
    if (
      nom.trim() === "" ||
      selectService.trim() === "" ||
      lieuReconnu.trim() === ""
    ) {
      // Affichez un message d'erreur ou effectuez toute autre action nécessaire
      alert("Veuillez remplir tous les champs requis.");
      return;
    }
  };

  //Separation nom et prénom
  const separerNomPrenom = (nomComplet: any, setNom: any, setPrenom: any) => {
    //Efface tous les espaces
    const nomPrenom = nomComplet.trim();
    // Diviser la chaîne en utilisant l'espace
    const parties = nomPrenom.split(" ");
    if (parties.length >= 1) {
      const nom = parties[0];
      const prenoms = parties.slice(1).join(" "); // Le reste de la chaîne est considéré comme les prénoms
      setNom(nom);
      setPrenom(prenoms);
      // Retourner un objet avec le nom et les prénoms
    } else {
      setNom("");
      setPrenom("");
    }
  };

  // Appel de la fonction pour obtenir un numéro aléatoire
  const randomReference = generateRandomNumber();

  //Nom
  const handleChangeNom = (e: any) => {
    if (/^[a-zA-Z0-9]+$/.test(e.target.value) || e.target.value === "") {
      setNom(e.target.value);
    }
  };

  //Prénom
  const handleChangePrenom = (e: any) => {
    if (/^[a-zA-Z0-9-" "]+$/.test(e.target.value) || e.target.value === "") {
      setPrenom(e.target.value);
    }
  };

  //Lieu de reconnaissance
  const handleChangeLieu = (e: any) => {
    setLieuReconnu(e.target.value);
  };

  //Langue
  const handleChangeLangue = (e: any) => {
    setLangueUtile(e.target.value);
  };

  //Type de service
  const handleChangeService = (e: any) => {
    setSelectService(e.target.value);
  };

  //Hide Modal
  const toggleModal = () => setIsModal(!isModal);

  //Nombre commande
  const handleChangeNbreCommande = (e: any) => {
    const valeurs = e.target.valueAsNumber;
    setNombreCommande(valeurs);
    // Vérifiez que la valeur est un nombre
    if (!isNaN(valeurs)) {
      setNombreCommande(parseInt(valeurs, 10));
    }
  };

  //Enregistrement reconnaissance
  const handleClick = async () => {
    const formData = new FormData();
    formData.append("langue", langueUtile);
    formData.append("type_service", selectService);
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("lieu_reconnu", lieuReconnu);
    formData.append("nbre_com", nombreCommande.toString());

    // const donne = Object.fromEntries(formData);
    // console.log("DATA", donne);application/json; charset=UTF-8, multipart/form-data
    try {
      const response = await axios.post(
        urlProd + "/reconnaissances/add",
        formData,
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      setIsSubmited(true);

      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);

      // Accéder à l'ID de la donnée insérée si disponible et le stocker dans l'état
      if (response.data.data && response.data.data.id) {
        setMontantSimpleMG(response.data.data.montantSimpleMG);
        setMontantExpressMG(response.data.data.montantExpressMG);
        setMontantSimpleFR(response.data.data.montantSimpleFR);
        setMontantExpressFR(response.data.data.montantExpressFR);
        setServiceCommande(response.data.data.type_service);
        setIdReconnaissance(response.data.data.id);
        setNbreReconnaissance(response.data.data.nbre_com);
        setLangueCommande(response.data.data.langue);
        setDataNomEnfant(response.data.data.nom);
        setDataPrenomEnfant(response.data.data.prenom);
        setDataService(response.data.data.type_service);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //Commande client
  const handleClickCommande = async () => {
    const sommeMontant = sommeCommande();
    const formData = new FormData();

    formData.append("date_commande", today);
    formData.append("ref_billet", randomReference.toString());
    formData.append("status", status);
    formData.append("fructueux", existe.toString());
    if (sommeMontant != undefined) {
      formData.append("montant", sommeMontant.toString());
    }
    formData.append("id_Client", idClient);

    if (idReconnaissance != null && idReconnaissance != "") {
      formData.append("id_Reconnaissance", idReconnaissance);
    }

    try {
      const response = await axios.post(urlProd + "/commandes/add", formData, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      setIsSubmited(true);
      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);

      if (response.data.data && response.data.data.id) {
        const idCom = response.data.data.id;
        setIdCommande(idCom);
        const montant = response.data.data.montant;
        const reference = response.data.data.ref_billet;
        const fructueux = response.data.data.fructueux;
        setDataMontant(montant);
        setDataRef(reference);
        setDataFructueux(fructueux);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //Somme de commande
  let somme = 0;
  const sommeCommande = () => {
    const montant = calculeMontant(
      serviceCommande,
      langueCommande,
      montantSimpleMG,
      montantExpressMG,
      montantSimpleFR,
      montantExpressFR,
      nbreReconnaissance
    );

    if (montant != undefined) {
      somme += montant;
    }
    return somme;
  };
  function calculeMontantDetail(
    service: any,
    nombreCommande: any,
    prixSimple: any,
    prixExpress: any
  ) {
    if (service == "Standard") {
      return nombreCommande * prixSimple;
    } else {
      return nombreCommande * prixExpress;
    }
  }
  function calculeMontant(
    service: any,
    langue: any,
    prixSimpleMG: any,
    prixExpressMG: any,
    prixSimpleFR: any,
    prixExpressFR: any,
    nombreCommande: any
  ) {
    if (service == "Standard" && langue == "Malagasy") {
      return nombreCommande * prixSimpleMG;
    } else if (service == "Standard" && langue == "Français") {
      return nombreCommande * prixSimpleFR;
    } else if (service == "Express" && langue == "Malagasy") {
      return nombreCommande * prixExpressMG;
    } else if (service == "Express" && langue == "Français") {
      return nombreCommande * prixExpressFR;
    }
  }

  useEffect(() => {
    if (isSubmited) {
      setNom("");
      setPrenom("");
      setLieuReconnu("");
      setNumAct("");
      setSelectService("");
      setNombreCommande(0);
      setIsSubmited(false);
    }
  }, [isSubmited]);

  //Retourne vide
  const handleVide = () => {
    setNom("");
    setPrenom("");
    setLieuReconnu("");
    setNumAct("");
    setSelectService("");
    setNombreCommande(1);
  };

  //Naviguer vers Accueil
  const NavigateAccueil = () => {
    history.push("/");
  };

  const handleSubmit = async (e: any) => {
    navigateToFinish();
    e.preventDefault();
    await handleClick();
  };

  useEffect(() => {
    if (
      (seconde == 0 || minute == 0) &&
      idCommande != 0 &&
      idCommande != null
    ) {
      const UtilisateurCopie = async () => {
        const val = await fetch(urlProd + `/commandes/${idCommande}`);
        const data = await val.json();
        // console.log("data", data);
        if (data.commande.fructueux != "" || data.commande.fructueux != null) {
          setDataFructueux(data.commande.fructueux);
          // console.log("aizaaaF", data.commande.fructueux);
        }
      };
      UtilisateurCopie();
    }
  }, [seconde, minute, idCommande]);

  return (
    <>
      <Navbar titreImg="/icon/logo.png" />
      <div className="pt-4 px-3">
        <h2 style={{ textAlign: "center", fontFamily: " Georgia, serif" }}>
          Demande de l'acte de reconnaissance{" "}
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="row mt-2 pt-3">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-2 "></div>
                <div className="col-sm-8 ">
                  <RadioButton
                    nameLabel="Langue"
                    radioValue1="Malagasy"
                    handleChangeRadio1={handleChangeLangue}
                    radioLabel1="Malagasy"
                    radioValue2="Français"
                    handleChangeRadio2={handleChangeLangue}
                    radioLabel2="Français"
                  />
                  <SelectService
                    label="Service :"
                    nameSelect="Choisissez votre service"
                    name1="Service Standard"
                    name2="Service Express"
                    selectValue={selectService}
                    handleSelected={handleChangeService}
                  />
                  <Input
                    label="Nom :"
                    type="text"
                    name="nom"
                    value={nom}
                    handleChange={handleChangeNom}
                    required="required"
                  />
                  <Input
                    label="Prénom(s) :"
                    name="prenom"
                    value={prenom}
                    handleChange={handleChangePrenom}
                  />
                  <Input
                    label="Lieu de reconnaissance :"
                    name="lieu"
                    value={lieuReconnu}
                    handleChange={handleChangeLieu}
                    required="required"
                  />
                  <Input
                    label="Nombre de commande :"
                    type="number"
                    min={1}
                    value={nombreCommande}
                    handleChange={handleChangeNbreCommande}
                    name="nbCommande"
                    required="required"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="row justify-content-center pt-2">
                    <button
                      type="button"
                      onClick={handleVide}
                      className="col-sm-6 btn btn-sm btn-primary mt-4 "
                      style={{
                        display:
                          idReconnaissance == "" || idReconnaissance == null
                            ? "block"
                            : "none",
                      }}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row justify-content-center pt-2">
                    <button
                      type="submit"
                      // onClick={tempRestant}
                      className="col-sm-6 btn btn-sm btn-primary mt-4"
                      style={{
                        display:
                          idReconnaissance == "" || idReconnaissance == null
                            ? "block"
                            : "none",
                      }}
                    >
                      Passer à la commande
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              {idReconnaissance != null && idReconnaissance != "" ? (
                <div className="row">
                  <div
                    className="col-sm-11 pt-2 mt-4 pb-2 "
                    style={{
                      borderRadius: "10px",
                      border: "1px solid #dee2e6 ",
                      // height: "170px",
                    }}
                  >
                    <h4
                      className="center"
                      style={{
                        fontFamily: " Georgia, serif",
                        textAlign: "center",
                      }}
                    >
                      Information sur votre commande :
                    </h4>
                    <div
                      style={{
                        fontFamily: " Georgia, serif",
                        marginLeft: "30px",
                      }}
                    >
                      <h6 className="col-sm-12 pt-2">Nom : {dataNomEnfant}</h6>
                      <h6 className="col-sm pt-2">
                        Prénom : {dataPrenomEnfant}
                      </h6>
                      <h6 className="col-sm pt-2">
                        Type de service : {dataService}
                      </h6>
                    </div>
                    <div className="row">
                      <div className="col-sm"></div>
                      <div className="col-sm">
                        <div className="row justify-content-center pt-2">
                          <button
                            type="button"
                            onClick={() => {
                              handleClickCommande();
                              toggleModal();
                            }}
                            style={{
                              display:
                                idClient == "" || idClient == null
                                  ? "none"
                                  : "block",
                            }}
                            className="col-sm-6 btn btn-sm btn-primary mt-4"
                          >
                            Commander
                          </button>
                          <Modal
                            title="Commande réussi !"
                            isOpen={isModal}
                            onClose={() => {
                              toggleModal();
                              NavigateAccueil();
                            }}
                          >
                            <hr />
                            <h5
                              style={{
                                fontFamily: " Georgia, serif",
                              }}
                            >
                              Détails de votre commande :
                            </h5>

                            <div className="row">
                              <div className="col-sm-6">
                                {" "}
                                Acte de reconnaissance :
                                <b> {nbreReconnaissance} </b>
                              </div>
                            </div>

                            <div>
                              <h6 className="col-sm pt-2">
                                Reférence : RECO-{dataRef}
                                <br />
                                Net à payer : {dataMontant} Ar
                                <br />
                              </h6>
                            </div>
                            <hr />

                            <div className="row justify-content-center pt-2">
                              <div className="timer">
                                <div className="container">
                                  <div className="timer_container">
                                    <div style={{ textAlign: "center" }}>
                                      <span
                                        style={{
                                          fontFamily: " Georgia, serif",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Veuillez patienter après quelques
                                        minutes pour obtenir votre réponse ...
                                      </span>
                                      <br />
                                      <span style={{ fontSize: "20px" }}>
                                        Temps restant:
                                      </span>{" "}
                                      {minute}:{seconde}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {minute == 0 && seconde == 0 ? (
                                <>
                                  {dataFructueux == 1 ? (
                                    <span style={{ textAlign: "center" }}>
                                      Passez prendre votre commande :
                                      {dataService == "Express" ? (
                                        <span>&nbsp;&nbsp;Après 1 heure</span>
                                      ) : (
                                        <span> &nbsp;&nbsp;Après 2 jours</span>
                                      )}
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        fontFamily: " Georgia, serif",
                                        fontSize: "16px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Veuillez contacter direct au responsable à
                                      l'hôtel de ville
                                    </span>
                                  )}
                                </>
                              ) : null}
                            </div>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

const CheckBox = ({ name, checked, onClick }: any) => {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        value=""
        checked={checked}
        onClick={onClick}
        id="flexCheckDefault"
      />
      <label className="form-check-label" htmlFor="flexCheckDefault">
        {name}
      </label>
    </div>
  );
};
const Input = ({
  label,
  type = "text",
  min = 1,
  name,
  handleChangefile,
  handleChange,
  accept,
  disable,
  value,
  style,
  checked,
  required,
}: any) => {
  return (
    <div className="form">
      <label htmlFor="" className="form-label">
        {label}
      </label>
      <>
        {type == "file" ? (
          <input
            accept="image/*"
            onChange={handleChangefile}
            name={name}
            type={type}
            min={min}
            className="form-control"
            required={required}
          />
        ) : (
          <input
            onChange={handleChange}
            name={name}
            type={type}
            value={value}
            disabled={disable}
            checked={checked}
            min={min}
            className="form-control"
            style={style}
            required={required}
          />
        )}
      </>
    </div>
  );
};

const SelectService = ({
  label,
  nameSelect,
  name,
  name1,
  name2,
  selectValue,
  handleSelected,
  id,
}: any) => {
  return (
    <>
      <label htmlFor="">{label}</label>
      <select
        className="form-select mb-2"
        aria-label="Default select example"
        name={name}
        value={selectValue}
        onChange={handleSelected}
        required
      >
        <option value="" key={id}>
          {nameSelect}
        </option>
        <option value="Standard">{name1}</option>
        <option value="Express">{name2}</option>
      </select>
    </>
  );
};
const SelectLieu = ({
  label,
  nameSelect,
  name,
  name1,
  name2,
  selectValue,
  handleSelected,
  id,
}: any) => {
  return (
    <>
      <label htmlFor="">{label}</label>
      <select
        className="form-select mb-2"
        aria-label="Default select example"
        name={name}
        value={selectValue}
        onChange={handleSelected}
        required
      >
        <option value="" key={id}>
          {nameSelect}
        </option>
        <option value="Mahajanga">{name1}</option>
        <option value="Mahabibo">{name2}</option>
      </select>
    </>
  );
};

function RadioButton({
  nameLabel,
  radioLabel1,
  radioLabel2,
  radioValue1,
  radioValue2,
  handleChangeRadio1,
  handleChangeRadio2,
}: any) {
  return (
    <div className="row">
      <div className="col-sm ">
        <label className="label text-gray-1000">{nameLabel} : &nbsp;</label>
        <div className="form-check-inline">
          <input
            type="radio"
            className="form-check-input"
            value={radioValue1}
            name="groupe2"
            id="malagasyRadio"
            onChange={handleChangeRadio1}
            required
          />
          <label className="form-check-label">{radioLabel1}</label>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div className="form-check-inline validate-input mb-2">
          <input
            type="radio"
            className="form-check-input"
            value={radioValue2}
            name="groupe2"
            id="francaisRadio"
            onChange={handleChangeRadio2}
          />
          <label className="form-check-label">{radioLabel2}</label>
        </div>
      </div>
    </div>
  );
}
function RadioButtonTerme({
  nameLabel,
  radioLabel1,
  radioLabel2,
  radioValue1,
  radioValue2,
  handleChangeRadio1,
  handleChangeRadio2,
}: any) {
  return (
    <div className="row">
      <div className="col-sm">
        <label className="label text-gray-1000">{nameLabel} : &nbsp;</label>
        <div className="form-check-inline">
          <input
            type="radio"
            className="form-check-input"
            value={radioValue1}
            name="type[]"
            id="DecesDelai"
            onChange={handleChangeRadio1}
            required
          />
          <label className="form-check-label">{radioLabel1}</label>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div className="form-check-inline validate-input mb-2">
          <input
            type="radio"
            className="form-check-input"
            value={radioValue2}
            name="type[]"
            id="JugSuppl"
            onChange={handleChangeRadio2}
          />
          <label className="form-check-label">{radioLabel2}</label>
        </div>
      </div>
    </div>
  );
}
