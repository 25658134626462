import React, { useEffect, useRef, useState } from "react";
import { Navbar } from "../component/Navbar";
import { useHistory, useLocation } from "react-router-dom";

import axios from "axios";

interface ClientProps {
  urls: string;
}
const initialData = {
  id: "",
  nom: "",
  prenom: "",
  contactDemandeur: "",
  adresseDemandeur: "",
  imageAvant: "",
  imageApres: "",
  relationFamiliale: "",
};
export const ClientInfo: React.FC = () => {
  const location = useLocation<ClientProps>();
  const urls = location.state?.urls || "";
  const isMountedRef = useRef<boolean>(true);

  const [data, setData] = useState(initialData);
  const [idClient, setIdClient] = useState(null);
  // const [dataNomEnfant, setDataNomEnfant] = useState("");
  // const [dataPrenomEnfant, setDataPrenomEnfant] = useState("");
  const history = useHistory();

  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [contactDemand, setContactDemand] = useState("");
  const [adresseDemand, setAdresseDemand] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [imageLoad, setImageLoad] = useState("");
  const [imageLoad1, setImageLoad1] = useState("");
  const [isSubmited, setIsSubmited] = useState(false);

  const urlProd = "https://commande.mairie-mahajanga.mg/api";
  // const urlProd = "http://127.0.0.1:8000/api";

  const handleNonVide = () => {
    // Vous pouvez vérifier ici si le formulaire est valide avant de passer à la commande
    if (
      nom.trim() === "" ||
      contactDemand.trim() === "" ||
      !imageLoad ||
      !imageLoad1
    ) {
      // Affichez un message d'erreur ou effectuez toute autre action nécessaire
      alert("Veuillez remplir tous les champs requis.");
      return;
    }
  };
  // console.log("aty", urls);

  useEffect(() => {
    // Mettre à jour la référence de montage lorsque le composant est démonté
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  //relation
  const handleSelectChange = (e: any) => {
    setSelectedValue(e.target.value);
    // data.relationFamiliale = e.target.value;
  };

  //Nom
  const handleChangeNom = (e: any) => {
    if (/^[a-zA-Z0-9]+$/.test(e.target.value) || e.target.value === "") {
      setNom(e.target.value);
      // nomInserer = e.target.value;
      data.nom = e.target.value;
    }
  };

  //Prénom
  const handleChangePrenom = (e: any) => {
    if (/^[a-zA-Z0-9-" "]+$/.test(e.target.value) || e.target.value === "") {
      setPrenom(e.target.value);
      data.prenom = e.target.value;
    }
  };

  //Contact de demandeur
  const handleChangeContact = (e: any) => {
    if (/^\d{0,10}$/.test(e.target.value)) {
      setContactDemand(e.target.value);
      data.contactDemandeur = e.target.value;
    }
  };

  //CIN avant
  const handleChangeCinAvant = (e: any) => {
    setImageLoad(e.target.files[0]);
    data.imageAvant = e.target.files[0];
  };

  //CIN Apres
  const handleChangeCinApres = (e: any) => {
    setImageLoad1(e.target.files[0]);
    data.imageApres = e.target.files[0];
  };

  //Adresse
  const handleChangeAdresse = (e: any) => {
    if (/^[a-zA-Z0-9-" "]+$/.test(e.target.value) || e.target.value === "") {
      setAdresseDemand(e.target.value);
      data.adresseDemandeur = e.target.value;
    }
  };

  const handleClick = async () => {
    // console.log("file", imageLoad, imageLoad1);
    const formData = new FormData();
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("contact", contactDemand);
    formData.append("adresse", adresseDemand);
    formData.append("relation", selectedValue);
    formData.append("cin_avant", imageLoad);
    formData.append("cin_apres", imageLoad1);

    // const donne = Object.fromEntries(formData);
    // console.log("DATA", donne);application/json; charset=UTF-8, multipart/form-data
    try {
      const response = await axios.post(urlProd + "/clients/add", formData, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      if (isMountedRef.current) {
        setIsSubmited(true);
      }
      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);

      // Accéder à l'ID de la donnée insérée si disponible et le stocker dans l'état
      if (response.data.data && response.data.data.id) {
        const insertedId = response.data.data.id;
        setIdClient(insertedId);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };
  useEffect(() => {
    if (isSubmited) {
      // Reset the form after a successful submission

      if (isMountedRef.current) {
        setData(initialData);
        setNom("");
        setPrenom("");
        setContactDemand("");
        setAdresseDemand("");
        setSelectedValue("");
        setImageLoad("");
        setImageLoad1("");
        // Rechargez la page actuelle
        // window.location.reload();
        // Reset other state variables as needed
        setIsSubmited(false); // Reset the submission status
      }
    }
  }, [isSubmited]);

  useEffect(() => {
    handleNavigate();
  }, [isSubmited]);

  //pour vider formulaire
  const initialFormulaire = () => {
    // setData(initialData);
    setNom("");
    setPrenom("");
    setContactDemand("");
    setAdresseDemand("");
    setSelectedValue("");
    setImageLoad("");
    setImageLoad1("");
  };

  // const handleNavigate = () => {
  //   if (idClient) {
  //     history.push(`/${urls}/${idClient}`);
  //   }
  // };
  const handleNavigate = () => {
    if (idClient != null) {
      history.push({
        pathname: `/${urls}`,
        state: { idClient: idClient },
      });
    }
  };

  const handleSubmit = async (e: any) => {
    handleNonVide();
    e.preventDefault();
    if (
      nom != "" ||
      contactDemand != "" ||
      imageLoad != "" ||
      imageLoad1 != ""
    ) {
      const confirmation = window.confirm(
        "Voulez-vous enregistrer les informations du client?"
      );
      if (confirmation) {
        await handleClick();
        initialFormulaire();
        // handleNavigate();
      }
    }
  };
  return (
    <>
      <Navbar titreImg="icon/logo.png" />
      <div className="pt-4 px-3">
        <h1 style={{ textAlign: "center", fontFamily: " Georgia, serif" }}>
          Information client{" "}
        </h1>

        <form onSubmit={handleSubmit}>
          <div className="row mt-2">
            <div className="col-sm-3"></div>
            <div className="col-sm-3">
              <Input
                label="Nom :"
                type="text"
                name="nom"
                value={nom}
                handleChange={handleChangeNom}
                required="required"
              />

              <Input
                label="Prénom(s) :"
                name="prenom"
                value={prenom}
                handleChange={handleChangePrenom}
              />

              <Input
                label="CIN recto:"
                type="file"
                name="imageFile"
                value={imageLoad}
                handleChangefile={handleChangeCinAvant}
                required="required"
              />

              <SelectRelation
                label="Lien familial :"
                nameSelect="Choisissez votre relation"
                name="relation"
                selectValue={selectedValue}
                handleSelected={handleSelectChange}
                name1="Moi-même"
                name2="Parents"
                name3="Frère"
                name4="Soeur"
                name5="Epoux"
                name6="Epouse"
                name7="Enfant"
              />
            </div>
            <div className="col-sm-3">
              <Input
                label="Contact :"
                name="contactDemande"
                value={contactDemand}
                handleChange={handleChangeContact}
                required="required"
              />

              <Input
                label="Adresse :"
                name="adresseDemande"
                value={adresseDemand}
                handleChange={handleChangeAdresse}
              />

              <Input
                label="CIN verso :"
                type="file"
                name="imageFile1"
                value={imageLoad1}
                handleChangefile={handleChangeCinApres}
                required="required"
              />
            </div>
            <div className="row mt-2">
              <div>
                <div className="row justify-content-center ">
                  <button
                    type="button"
                    onClick={initialFormulaire}
                    className="col-sm-2 btn btn-sm btn-primary mt-4"
                  >
                    Annuler
                  </button>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <button
                    type="submit"
                    // onClick={() => {
                    //   navigateToCommandeLigne();
                    // }}
                    className="col-sm-2 btn btn-sm btn-primary mt-4"
                  >
                    Enregistrer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div></div>
      </div>
    </>
  );
};

const Input = ({
  label,
  type = "text",
  min = 1,
  name,
  handleChangefile,
  handleChange,
  accept,
  disable,
  value,
  style,
  required,
}: any) => {
  return (
    <div className="form">
      <label htmlFor="" className="form-label">
        {label}
      </label>
      <>
        {type == "file" ? (
          <input
            accept="image/*"
            onChange={handleChangefile}
            name={name}
            type={type}
            min={min}
            className="form-control"
            required={required}
          />
        ) : (
          <input
            onChange={handleChange}
            name={name}
            type={type}
            value={value}
            disabled={disable}
            className="form-control"
            style={style}
            required={required}
          />
        )}
      </>
    </div>
  );
};

const SelectRelation = ({
  label,
  nameSelect,
  name,
  name1,
  name2,
  name3,
  name4,
  name5,
  name6,
  name7,
  selectValue,
  handleSelected,
  id,
}: any) => {
  return (
    <>
      <label htmlFor="">{label}</label>
      <select
        className="form-select mb-2"
        aria-label="Default select example"
        name={name}
        value={selectValue}
        onChange={handleSelected}
        required
      >
        <option value="" key={id}>
          {nameSelect}
        </option>
        <option value="Moi-même">{name1}</option>
        <option value="Parents">{name2}</option>
        <option value="Frère">{name3}</option>
        <option value="Soeur">{name4}</option>
        <option value="Epoux">{name5}</option>
        <option value="Epouse">{name6}</option>
        <option value="Enfant">{name7}</option>
      </select>
    </>
  );
};
