import { useEffect, useState } from "react";
// import { GetClientByMail } from "../../api/Naissance";
import { Footer } from "../Footer";
import { Accueil } from "./Accueil";
import { ImageMenu, MenuBas } from "./Menu";
import { Navbar } from "../Navbar";

export const SiteClient = () => {
  return (
    <div>
      <Navbar titreImg="icon/logo.png" />
      <ImageMenu />
      <MenuBas urlValue="" />
      <TextChearch />
      <Accueil />
      <Footer />
    </div>
  );
};

function TextChearch() {
  return <div className={"container-sm px-3 mt-4 py-1 mb-3"}></div>;
}
