import React from "react";
import teteModal from "../../assets/img/notification.png";

import "./Modal.css";

interface ModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
}
export const Modal: React.FC<ModalProps> = ({
  title,
  isOpen,
  onClose,
  children,
}) =>
  isOpen ? (
    <div className={"modal"}>
      <div className={"modal__overlay"} />
      <div className={"modal__box"}>
        <div className="modal_entete">
          <img className="enTete" src={teteModal} alt="" />
        </div>
        <div className={"modal__title"}>{title}</div>
        <div className={"modal__content"}>{children}</div>
        <button
          className={" btn btn-danger modal__close-btn "}
          onClick={onClose}
        >
          Fermer
        </button>
      </div>
    </div>
  ) : null;
