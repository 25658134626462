import { useEffect, useState } from "react";
import { Navbar } from "../component/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { Modal } from "../component/Modal/Modal";

interface ClientIdProps {
  idClient: string;
}
const initialData = {
  id: "",
  nomEnfant: "",
  prenomEnfant: "",
  dateNaissance: "",
  lieuNaissance: "",
  numeroActe: "",
  nomPere: "",
  prenomPere: "",
  nomMere: "",
  prenomMere: "",
};
export const AjouterNaissance: React.FC = () => {
  const location = useLocation<ClientIdProps>();
  const idClient = location.state?.idClient || "";

  const [data, setData] = useState(initialData);
  const [idNaissance, setIdNaissance] = useState(null);
  const [nbreNaissance, setNbreNaissance] = useState("");
  const [idCoutume, setIdCoutume] = useState(null);
  const [nbreCoutume, setNbreCoutume] = useState("");
  const [prixCoutumeSimple, setPrixCoutumeSimple] = useState(0);
  const [prixCoutumeExpress, setPrixCoutumeExpress] = useState(0);
  const [idMatrimonial, setIdMatrimonial] = useState(null);
  const [nbreMatrimonial, setNbreMatrimonial] = useState("");
  const [prixMatrimonialSimple, setPrixMatrimonialSimple] = useState(0);
  const [prixMatrimonialExpress, setPrixMatrimonialExpress] = useState(0);
  const [idCelibat, setIdCelibat] = useState(null);
  const [nbreCelibat, setNbreCelibat] = useState("");
  const [prixCelibatSimple, setPrixCelibatSimple] = useState(0);
  const [prixCelibatExpress, setPrixCelibatExpress] = useState(0);
  const [idBulletin, setIdBulletin] = useState(null);
  const [nbreBulletin, setNbreBulletin] = useState("");
  const [prixBulletinSimple, setPrixBulletinSimple] = useState(0);
  const [prixBulletinExpress, setPrixBulletinExpress] = useState(0);
  const [idLegitimation, setIdLegitimation] = useState(null);
  const [nbreLegitimation, setNbreLegitimation] = useState("");
  const [prixLegitimationSimpleMG, setPrixLegitimationSimpleMG] = useState(0);
  const [prixLegitimationSimpleFR, setPrixLegitimationSimpleFR] = useState(0);
  const [prixLegitimationExpressMG, setPrixLegitimationExpressMG] = useState(0);
  const [prixLegitimationExpressFR, setPrixLegitimationExpressFR] = useState(0);
  const [idChangement, setIdChangement] = useState(null);
  const [nbreChangement, setNbreChangement] = useState("");
  const [prixChangementSimple, setPrixChangementSimple] = useState(0);
  const [prixChangementExpress, setPrixChangementExpress] = useState(0);
  const [idAdoption, setIdAdoption] = useState(null);
  const [nbreAdoption, setNbreAdoption] = useState("");
  const [prixAdoptionSimpleMG, setPrixAdoptionSimpleMG] = useState(0);
  const [prixAdoptionSimpleFR, setPrixAdoptionSimpleFR] = useState(0);
  const [prixAdoptionExpressMG, setPrixAdoptionExpressMG] = useState(0);
  const [prixAdoptionExpressFR, setPrixAdoptionExpressFR] = useState(0);
  const [idExtrait, setIdExtrait] = useState(null);
  const [nbreExtrait, setNbreExtrait] = useState("");
  const [prixExtraitSimple, setPrixExtraitSimple] = useState(0);
  const [prixExtraitExpress, setPrixExtraitExpress] = useState(0);
  const [idMariage, setIdMariage] = useState("");
  const [nbreMariage, setNbreMariage] = useState("");
  const [prixMariageSimpleMG, setPrixMariageSimpleMG] = useState(0);
  const [prixMariageSimpleFR, setPrixMariageSimpleFR] = useState(0);
  const [prixMariageExpressMG, setPrixMariageExpressMG] = useState(0);
  const [prixMariageExpressFR, setPrixMariageExpressFR] = useState(0);
  const [idDivorce, setIdDivorce] = useState("");
  const [nbreDivorce, setNbreDivorce] = useState("");
  const [prixDivorceSimpleMG, setPrixDivorceSimpleMG] = useState(0);
  const [prixDivorceSimpleFR, setPrixDivorceSimpleFR] = useState(0);
  const [prixDivorceExpressMG, setPrixDivorceExpressMG] = useState(0);
  const [prixDivorceExpressFR, setPrixDivorceExpressFR] = useState(0);
  const [idRectification, setIdRectification] = useState("");
  const [nbreRectification, setNbreRectification] = useState("");
  const [prixRectificationSimple, setPrixRectificationSimple] = useState(0);
  const [prixRectificationExpress, setPrixRectificationExpress] = useState(0);
  const [dataNomEnfant, setDataNomEnfant] = useState("");
  const [dataPrenomEnfant, setDataPrenomEnfant] = useState("");
  const [dataService, setDataService] = useState("");
  const [idCommande, setIdCommande] = useState(null);
  const [montantSimpleMG, setMontantSimpleMG] = useState(0);
  const [montantExpressMG, setMontantExpressMG] = useState(0);
  const [montantSimpleFR, setMontantSimpleFR] = useState(0);
  const [montantExpressFR, setMontantExpressFR] = useState(0);
  const [langueCommande, setLangueCommande] = useState("");
  const [serviceCommande, setServiceCommande] = useState("");

  const [dataMontant, setDataMontant] = useState("");
  const [dataRef, setDataRef] = useState("");
  const [isModal, setIsModal] = useState(false);
  const history = useHistory();

  const [langueUtile, setLangueUtile] = useState("");
  const [selectService, setSelectService] = useState("");
  const [sigle, setSigle] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [dateNaiss, setDateNaiss] = useState("");
  const [lieuNaiss, setLieuNaiss] = useState("");
  const [numAct, setNumAct] = useState("");
  const [status, setStatus] = useState("Encours");

  const [nomCompletPapa, setNomCompletPapa] = useState("");
  const [nomPapa, setNomPapa] = useState("");
  const [prenomPapa, setPrenomPapa] = useState("");
  const [nomCompletMama, setNomCompletMama] = useState("");
  const [nomMama, setNomMama] = useState("");
  const [prenomMama, setPrenomMama] = useState("");
  const [nomCompletEpouxMariage, setNomCompletEpouxMariage] = useState("");
  const [nomEpouxMariage, setNomEpouxMariage] = useState("");
  const [prenomEpouxMariage, setPrenomEpouxMariage] = useState("");
  const [nomCompletEpouseMariage, setNomCompletEpouseMariage] = useState("");
  const [nomEpouseMariage, setNomEpouseMariage] = useState("");
  const [prenomEpouseMariage, setPrenomEpouseMariage] = useState("");
  const [nomCompletEpouxDivorce, setNomCompletEpouxDivorce] = useState("");
  const [nomEpouxDivorce, setNomEpouxDivorce] = useState("");
  const [prenomEpouxDivorce, setPrenomEpouxDivorce] = useState("");
  const [nomCompletEpouseDivorce, setNomCompletEpouseDivorce] = useState("");
  const [nomEpouseDivorce, setNomEpouseDivorce] = useState("");
  const [prenomEpouseDivorce, setPrenomEpouseDivorce] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [hideButton, sethHideButton] = useState(0);
  const [existe, setExiste] = useState(0);

  const [isSubmited, setIsSubmited] = useState(false);
  const [nombreCommande, setNombreCommande] = useState<number>(1);
  const [nombreCoutume, setNombreCoutume] = useState<number>(1);
  const [nombreMatrimonial, setNombreMatrimonial] = useState<number>(1);
  const [nombreCelibat, setNombreCelibat] = useState<number>(1);
  const [nombreBulletin, setNombreBulletin] = useState<number>(1);
  const [nombreExtrait, setNombreExtrait] = useState<number>(1);
  const [nombreLegitimation, setNombreLegitimation] = useState<number>(1);
  const [nombreAdoption, setNombreAdoption] = useState<number>(1);
  const [nombreChangement, setNombreChangement] = useState<number>(1);
  const [nombreRectificat, setNombreRectificat] = useState<number>(1);
  const [nombreMariage, setNombreMariage] = useState<number>(1);
  const [nombreDivorce, setNombreDivorce] = useState<number>(1);

  // Checkbox state
  const [isChecked, setIsChecked] = useState(false);
  const [displayCoutume, setDisplayCoutume] = useState(0);
  const [displayChange, setDisplayChange] = useState(0);
  const [displayMatri, setDisplayMatri] = useState(0);
  const [displayLegitimation, setDisplayLegitimation] = useState(0);
  const [displayCelibat, setDisplayCelibat] = useState(0);
  const [displayBulletin, setDisplayBulletin] = useState(0);
  const [displayRectificat, setDisplayRectificat] = useState(0);
  const [displayAdoption, setDisplayAdoption] = useState(0);
  const [displayExtrait, setDisplayExtrait] = useState(0);
  const [displayMariage, setDisplayMariage] = useState(0);
  const [displayDivorce, setDisplayDivorce] = useState(0);
  const [displayReconnaissance, setDisplayReconnaissance] = useState(0);

  const [dateAdoption, setDateAdoption] = useState("");
  const [dateChangement, setDateChangement] = useState("");
  const [dateRectificat, setDateRectificat] = useState("");
  const [acteRectificat, setActeRecticat] = useState("Naissance");
  const [dateMariage, setDateMariage] = useState("");
  const [dateDivorce, setDateDivorce] = useState("");
  const [dataFructueux, setDataFructueux] = useState(0);

  const [seconde, setSeconde] = useState(60);
  const [minute, setMinute] = useState(2);

  const urlProd = "https://commande.mairie-mahajanga.mg/api";
  // const urlProd = "http://127.0.0.1:8000/api";

  const today = new Date().toISOString().split("T")[0];
  let timer: NodeJS.Timeout;

  useEffect(() => {
    if ((seconde > 0 || minute > 0) && idCommande != "" && idCommande != null) {
      timer = setInterval(() => {
        if (seconde > 0) {
          setSeconde(seconde - 1);
        } else if (minute > 0) {
          setMinute(minute - 1);
          setSeconde(59);
        } else {
          clearInterval(timer); // Stop the timer when both minute and seconde reach 0.
        }
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [seconde, minute, idCommande]);

  function generateRandomNumber() {
    const min = 1000000;
    const max = 9999999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber;
  }

  //Navigation vers commande
  const navigateToFinish = () => {
    // Vous pouvez vérifier ici si le formulaire est valide avant de passer à la commande
    if (
      nom.trim() === "" ||
      langueUtile.trim() === "" ||
      selectService.trim() === "" ||
      dateNaiss.trim() === "" ||
      lieuNaiss.trim() === ""
    ) {
      // Affichez un message d'erreur ou effectuez toute autre action nécessaire
      alert("Veuillez remplir tous les champs requis.");
      return;
    }
  };

  //Separation nom et prénom
  const separerNomPrenom = (nomComplet: any, setNom: any, setPrenom: any) => {
    //Efface tous les espaces
    const nomPrenom = nomComplet.trim();
    // Diviser la chaîne en utilisant l'espace
    const parties = nomPrenom.split(" ");
    if (parties.length >= 1) {
      const nom = parties[0];
      const prenoms = parties.slice(1).join(" "); // Le reste de la chaîne est considéré comme les prénoms
      setNom(nom);
      setPrenom(prenoms);
      // Retourner un objet avec le nom et les prénoms
    } else {
      setNom("");
      setPrenom("");
    }
  };

  //fonction Hide and Show
  const showHideMariage = () => {
    setDisplayMariage((prev) => (prev === 1 ? 0 : 1));
  };
  const showHideDivorce = () => {
    setDisplayDivorce((prev) => (prev === 1 ? 0 : 1));
  };
  const showHideReconnaissance = () => {
    setDisplayReconnaissance((prev) => (prev === 1 ? 0 : 1));
  };
  const showHideAdoption = () => {
    setDisplayAdoption((prev) => (prev === 1 ? 0 : 1));
  };
  const showHidExtrait = () => {
    setDisplayExtrait((prev) => (prev === 1 ? 0 : 1));
  };
  const showHideRectificat = () => {
    setDisplayRectificat((prev) => (prev === 1 ? 0 : 1));
  };
  const showHideBulletin = () => {
    setDisplayBulletin((prev) => (prev === 1 ? 0 : 1));
  };
  const showHideCelibat = () => {
    setDisplayCelibat((prev) => (prev === 1 ? 0 : 1));
  };
  const showHideLegitimation = () => {
    setDisplayLegitimation((prev) => (prev === 1 ? 0 : 1));
  };
  const showHideCoutume = () => {
    setDisplayCoutume((prev) => (prev === 1 ? 0 : 1));
  };
  const showHideMatrimonial = () => {
    setDisplayMatri((prevDisplay) => (prevDisplay === 1 ? 0 : 1));
  };
  const showHideChangement = () => {
    setDisplayChange((prevDisplay) => (prevDisplay === 1 ? 0 : 1));
  };
  // Appel de la fonction pour obtenir un numéro aléatoire
  const randomReference = generateRandomNumber();

  //Nom
  const handleChangeNom = (e: any) => {
    if (/^[a-zA-Z0-9]+$/.test(e.target.value) || e.target.value === "") {
      setNom(e.target.value);
      // nomInserer = e.target.value;
      data.nomEnfant = e.target.value;
    }
  };

  //Prénom
  const handleChangePrenom = (e: any) => {
    if (/^[a-zA-Z0-9-" "]+$/.test(e.target.value) || e.target.value === "") {
      setPrenom(e.target.value);
      data.prenomEnfant = e.target.value;
    }
  };

  //Lieu de naissance
  const handleChangeLieu = (e: any) => {
    setLieuNaiss(e.target.value);
  };

  //Date de Naissance
  const handleChangeNaiss = (e: any) => {
    setDateNaiss(e.target.value);
    data.dateNaissance = e.target.value;
  };

  //Numéro de l'acte
  const handleChangeNumActe = (e: any) => {
    if (/^[a-zA-Z0-9]+$/.test(e.target.value) || e.target.value === "") {
      setNumAct(e.target.value);
      data.numeroActe = e.target.value;
    }
  };

  //Nom de pere
  const handleChangeNomCompletPere = (e: any) => {
    if (/^[a-zA-Z0-9-" "]+$/.test(e.target.value) || e.target.value === "") {
      setNomCompletPapa(e.target.value);
      separerNomPrenom(e.target.value, setNomPapa, setPrenomPapa);
    }
  };

  //Nom de mere
  const handleChangeNomCompletMere = (e: any) => {
    if (/^[a-zA-Z0-9-" "]+$/.test(e.target.value) || e.target.value === "") {
      setNomCompletMama(e.target.value);
      separerNomPrenom(e.target.value, setNomMama, setPrenomMama);
    }
  };

  //Langue
  const handleChangeLangue = (e: any) => {
    setLangueUtile(e.target.value);
  };

  //Signe
  const handleChangeSigne = (e: any) => {
    setSigle(e.target.value);
  };

  //Type de service
  const handleChangeService = (e: any) => {
    setSelectService(e.target.value);
  };

  //Date adoption
  const handleChangeDateAdoption = (e: any) => {
    setDateAdoption(e.target.value);
  };

  //Date Changement nom
  const handleChangeDateChangement = (e: any) => {
    setDateChangement(e.target.value);
  };

  //Date rectification
  const handleChangeDateRectificat = (e: any) => {
    setDateRectificat(e.target.value);
  };

  //Date Mariage puis Divorce
  const handleChangeDateMariage = (e: any) => {
    setDateMariage(e.target.value);
  };
  const handleChangeNomEpouxMariage = (e: any) => {
    if (/^[a-zA-Z0-9-" "]+$/.test(e.target.value) || e.target.value === "") {
      setNomCompletEpouxMariage(e.target.value);
      separerNomPrenom(
        e.target.value,
        setNomEpouxMariage,
        setPrenomEpouxMariage
      );
    }
  };
  const handleChangeNomEpouseMariage = (e: any) => {
    if (/^[a-zA-Z0-9-" "]+$/.test(e.target.value) || e.target.value === "") {
      setNomCompletEpouseMariage(e.target.value);
      separerNomPrenom(
        e.target.value,
        setNomEpouseMariage,
        setPrenomEpouseMariage
      );
    }
  };
  const handleChangeDateDivorce = (e: any) => {
    setDateDivorce(e.target.value);
  };
  const handleChangeNomEpouxDivorce = (e: any) => {
    if (/^[a-zA-Z0-9-" "]+$/.test(e.target.value) || e.target.value === "") {
      setNomCompletEpouxDivorce(e.target.value);
      separerNomPrenom(
        e.target.value,
        setNomEpouxDivorce,
        setPrenomEpouxDivorce
      );
    }
  };
  const handleChangeNomEpouseDivorce = (e: any) => {
    if (/^[a-zA-Z0-9-" "]+$/.test(e.target.value) || e.target.value === "") {
      setNomCompletEpouseDivorce(e.target.value);
      separerNomPrenom(
        e.target.value,
        setNomEpouseDivorce,
        setPrenomEpouseDivorce
      );
    }
  };
  //Hide Modal
  const toggleModal = () => setIsModal(!isModal);

  //Nombre commande
  const handleChangeNbreCommande = (e: any) => {
    const valeurs = e.target.valueAsNumber;
    setNombreCommande(valeurs);
    // Vérifiez que la valeur est un nombre
    if (!isNaN(valeurs)) {
      setNombreCommande(parseInt(valeurs, 10));
    }
  };

  //Nombre commande Coutume
  const handleChangeNbreCoutume = (e: any) => {
    const valeur = e.target.valueAsNumber;
    setNombreCoutume(valeur);
    if (!isNaN(valeur)) {
      setNombreCoutume(parseInt(valeur, 10));
    }
  };

  //Nombre commande Matrimonial
  const handleChangeNbreMatrimonial = (e: any) => {
    const valeur = e.target.valueAsNumber;
    setNombreMatrimonial(valeur);
    if (!isNaN(valeur)) {
      setNombreMatrimonial(parseInt(valeur, 10));
    }
  };

  //Nombre commande Celibat

  const handleChangeNbreCelibat = (e: any) => {
    const valeur = e.target.valueAsNumber;
    setNombreCelibat(valeur);
    if (!isNaN(valeur)) {
      setNombreCelibat(parseInt(valeur, 10));
    }
  };

  //Nombre Bulletin
  const handleChangeNbreBulletin = (e: any) => {
    const valeur = e.target.valueAsNumber;
    setNombreBulletin(valeur);
    if (!isNaN(valeur)) {
      setNombreBulletin(parseInt(valeur, 10));
    }
  };

  //Nombre Extrait
  const handleChangeNbreExtrait = (e: any) => {
    const valeur = e.target.valueAsNumber;
    setNombreExtrait(valeur);
    if (!isNaN(valeur)) {
      setNombreExtrait(parseInt(valeur, 10));
    }
  };
  //Nombre Legitimation
  const handleChangeNbreLegitimation = (e: any) => {
    const valeur = e.target.valueAsNumber;
    setNombreLegitimation(valeur);
    if (!isNaN(valeur)) {
      setNombreLegitimation(parseInt(valeur, 10));
    }
  };

  //Nombre Adoption
  const handleChangeNbreAdoption = (e: any) => {
    const valeur = e.target.valueAsNumber;
    setNombreAdoption(valeur);
    if (!isNaN(valeur)) {
      setNombreAdoption(parseInt(valeur, 10));
    }
  };

  //Nombre Changement nom
  const handleChangeNbreChangement = (e: any) => {
    const valeur = e.target.valueAsNumber;
    setNombreChangement(valeur);
    if (!isNaN(valeur)) {
      setNombreChangement(parseInt(valeur, 10));
    }
  };

  //Nombre rectificat
  const handleChangeNbreRectificat = (e: any) => {
    const valeur = e.target.valueAsNumber;
    setNombreRectificat(valeur);
    if (!isNaN(valeur)) {
      setNombreRectificat(parseInt(valeur, 10));
    }
  };
  const handleChangeNbreMariage = (e: any) => {
    const valeur = e.target.valueAsNumber;
    setNombreMariage(valeur);
    if (!isNaN(valeur)) {
      setNombreMariage(parseInt(valeur, 10));
    }
  };
  const handleChangeNbreDivorce = (e: any) => {
    const valeur = e.target.valueAsNumber;
    setNombreDivorce(valeur);
    if (!isNaN(valeur)) {
      setNombreDivorce(parseInt(valeur, 10));
    }
  };
  //Enregistrement Naissance
  const handleClick = async () => {
    const formData = new FormData();
    formData.append("langue", langueUtile);
    formData.append("type_service", selectService);
    formData.append("sigle", sigle);
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("date_naiss", dateNaiss);
    formData.append("lieu_naiss", lieuNaiss);
    formData.append("num_acte", numAct);
    formData.append("nom_pere", nomPapa);
    formData.append("prenom_pere", prenomPapa);
    formData.append("nom_mere", nomMama);
    formData.append("prenom_mere", prenomMama);
    formData.append("nbre_com", nombreCommande.toString());

    // const donne = Object.fromEntries(formData);
    // console.log("DATA", donne);application/json; charset=UTF-8, multipart/form-data
    try {
      const response = await axios.post(urlProd + "/naissances/add", formData, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      setIsSubmited(true);

      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);

      // Accéder à l'ID de la donnée insérée si disponible et le stocker dans l'état
      if (response.data.data && response.data.data.id) {
        const insertedId = response.data.data.id;
        const nombreCopie = response.data.data.nbre_com;
        const nomEnfant = response.data.data.nom;
        const prenomEnfant = response.data.data.prenom;
        const service = response.data.data.type_service;

        setMontantSimpleMG(response.data.data.montantSimpleMG);
        setMontantExpressMG(response.data.data.montantExpressMG);
        setMontantSimpleFR(response.data.data.montantSimpleFR);
        setMontantExpressFR(response.data.data.montantExpressFR);
        setLangueCommande(response.data.data.langue);
        setServiceCommande(response.data.data.type_service);
        setIdNaissance(insertedId);
        setNbreNaissance(nombreCopie);
        setDataNomEnfant(nomEnfant);
        setDataPrenomEnfant(prenomEnfant);
        setDataService(service);
        // setDataNumActEnfant(acteNaiss);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //Enregistrement Coutume
  const handleAddCoutume = async () => {
    const formData = new FormData();
    formData.append("type_service", selectService);
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("date_naiss", dateNaiss);
    formData.append("lieu_naiss", lieuNaiss);
    formData.append("num_acte", numAct);
    formData.append("nom_pere", nomPapa);
    formData.append("prenom_pere", prenomPapa);
    formData.append("nom_mere", nomMama);
    formData.append("prenom_mere", prenomMama);
    formData.append("nbre_com", nombreCoutume.toString());

    // const donne = Object.fromEntries(formData);
    // console.log("DATA", donne);application/json; charset=UTF-8, multipart/form-data
    try {
      const response = await axios.post(urlProd + "/coutumes/add", formData, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      setIsSubmited(true);

      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);

      // Accéder à l'ID de la donnée insérée si disponible et le stocker dans l'état
      if (response.data.data && response.data.data.id) {
        setIdCoutume(response.data.data.id);
        setNbreCoutume(response.data.data.nbre_com);
        setPrixCoutumeSimple(response.data.data.montantSimple);
        setPrixCoutumeExpress(response.data.data.montantExpress);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //Enregistrement Matrimonial
  const handleAddMatrimonial = async () => {
    const formData = new FormData();
    formData.append("type_service", selectService);
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("date_naiss", dateNaiss);
    formData.append("lieu_naiss", lieuNaiss);
    formData.append("num_acte", numAct);
    formData.append("nom_pere", nomPapa);
    formData.append("prenom_pere", prenomPapa);
    formData.append("nom_mere", nomMama);
    formData.append("prenom_mere", prenomMama);
    formData.append("nbre_com", nombreMatrimonial.toString());

    // const donne = Object.fromEntries(formData);
    // console.log("DATA", donne);application/json; charset=UTF-8, multipart/form-data
    try {
      const response = await axios.post(
        urlProd + "/matrimonals/add",
        formData,
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      setIsSubmited(true);

      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);

      // Accéder à l'ID de la donnée insérée si disponible et le stocker dans l'état
      if (response.data.data && response.data.data.id) {
        setIdMatrimonial(response.data.data.id);
        setNbreMatrimonial(response.data.data.nbre_com);
        setPrixMatrimonialSimple(response.data.data.montantSimple);
        setPrixMatrimonialExpress(response.data.data.montantExpress);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //Enregistrement Celibat
  const handleAddCelibat = async () => {
    const formData = new FormData();
    formData.append("type_service", selectService);
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("date_naiss", dateNaiss);
    formData.append("lieu_naiss", lieuNaiss);
    formData.append("num_acte", numAct);
    formData.append("nom_pere", nomPapa);
    formData.append("prenom_pere", prenomPapa);
    formData.append("nom_mere", nomMama);
    formData.append("prenom_mere", prenomMama);
    formData.append("nbre_com", nombreCelibat.toString());

    // const donne = Object.fromEntries(formData);
    // console.log("DATA", donne);application/json; charset=UTF-8, multipart/form-data
    try {
      const response = await axios.post(urlProd + "/celibats/add", formData, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      setIsSubmited(true);

      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);
      // Accéder à l'ID de la donnée insérée si disponible et le stocker dans l'état
      if (response.data.data && response.data.data.id) {
        setIdCelibat(response.data.data.id);
        setNbreCelibat(response.data.data.nbre_com);
        setPrixCelibatSimple(response.data.data.montantSimple);
        setPrixCelibatExpress(response.data.data.montantExpress);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //Enregistrement Bulletin
  const handleAddBulletin = async () => {
    const formData = new FormData();
    formData.append("type_service", selectService);
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("date_naiss", dateNaiss);
    formData.append("lieu_naiss", lieuNaiss);
    formData.append("num_acte", numAct);
    formData.append("nom_pere", nomPapa);
    formData.append("prenom_pere", prenomPapa);
    formData.append("nom_mere", nomMama);
    formData.append("prenom_mere", prenomMama);
    formData.append("nbre_com", nombreBulletin.toString());

    // const donne = Object.fromEntries(formData);
    // console.log("DATA", donne);application/json; charset=UTF-8, multipart/form-data
    try {
      const response = await axios.post(urlProd + "/bulletins/add", formData, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      setIsSubmited(true);

      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);
      // Accéder à l'ID de la donnée insérée si disponible et le stocker dans l'état
      if (response.data.data && response.data.data.id) {
        setIdBulletin(response.data.data.id);
        setNbreBulletin(response.data.data.nbre_com);
        setPrixBulletinSimple(response.data.data.montantSimple);
        setPrixBulletinExpress(response.data.data.montantExpress);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //Enregistrement Legitimation
  const handleAddLegitimation = async () => {
    const formData = new FormData();
    formData.append("langue", langueUtile);
    formData.append("type_service", selectService);
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("date_naiss", dateNaiss);
    formData.append("lieu_naiss", lieuNaiss);
    formData.append("num_acte", numAct);
    formData.append("nom_pere", nomPapa);
    formData.append("prenom_pere", prenomPapa);
    formData.append("nom_mere", nomMama);
    formData.append("prenom_mere", prenomMama);
    formData.append("nbre_com", nombreLegitimation.toString());
    try {
      const response = await axios.post(
        urlProd + "/legitimations/add",
        formData,
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      setIsSubmited(true);

      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);
      // Accéder à l'ID de la donnée insérée si disponible et le stocker dans l'état
      if (response.data.data && response.data.data.id) {
        setIdLegitimation(response.data.data.id);
        setNbreLegitimation(response.data.data.nbre_com);
        setPrixLegitimationSimpleMG(response.data.data.montantSimpleMG);
        setPrixLegitimationSimpleFR(response.data.data.montantSimpleFR);
        setPrixLegitimationExpressMG(response.data.data.montantExpressMG);
        setPrixLegitimationExpressFR(response.data.data.montantExpressFR);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //Enregistrement Extrait
  const handleAddExtrait = async () => {
    const formData = new FormData();
    formData.append("type_service", selectService);
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("date_naiss", dateNaiss);
    formData.append("lieu_naiss", lieuNaiss);
    formData.append("num_acte", numAct);
    formData.append("nom_pere", nomPapa);
    formData.append("prenom_pere", prenomPapa);
    formData.append("nom_mere", nomMama);
    formData.append("prenom_mere", prenomMama);
    formData.append("nbre_com", nombreExtrait.toString());

    // const donne = Object.fromEntries(formData);
    // console.log("DATA", donne);application/json; charset=UTF-8, multipart/form-data
    try {
      const response = await axios.post(urlProd + "/extraits/add", formData, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      setIsSubmited(true);

      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);
      // Accéder à l'ID de la donnée insérée si disponible et le stocker dans l'état
      if (response.data.data && response.data.data.id) {
        setIdExtrait(response.data.data.id);
        setNbreExtrait(response.data.data.nbre_com);
        setPrixExtraitSimple(response.data.data.montantSimple);
        setPrixExtraitExpress(response.data.data.montantExpress);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //save Adoption
  const handleAddAdoption = async () => {
    const formData = new FormData();
    formData.append("langue", langueUtile);
    formData.append("type_service", selectService);
    formData.append("nom_enfant", nom);
    formData.append("prenom_enfant", prenom);
    formData.append("date_ado", dateAdoption);
    // formData.append("num_acte", numAct);
    formData.append("nbre_com", nombreAdoption.toString());
    try {
      const response = await axios.post(urlProd + "/adoptions/add", formData, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      setIsSubmited(true);

      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);
      // Accéder à l'ID de la donnée insérée si disponible et le stocker dans l'état
      if (response.data.data && response.data.data.id) {
        setIdAdoption(response.data.data.id);
        setNbreAdoption(response.data.data.nbre_com);
        setPrixAdoptionSimpleMG(response.data.data.montantSimpleMG);
        setPrixAdoptionSimpleFR(response.data.data.montantSimpleFR);
        setPrixAdoptionExpressMG(response.data.data.montantExpressMG);
        setPrixAdoptionExpressFR(response.data.data.montantExpressFR);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //save Changement de nom
  const handleAddChangementNom = async () => {
    const formData = new FormData();
    formData.append("type_service", selectService);
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("date_acte", dateChangement);
    formData.append("nbre_com", nombreChangement.toString());

    // const donne = Object.fromEntries(formData);
    // console.log("DATA", donne);application/json; charset=UTF-8, multipart/form-data
    try {
      const response = await axios.post(
        urlProd + "/changements/add",
        formData,
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      setIsSubmited(true);

      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);
      // Accéder à l'ID de la donnée insérée si disponible et le stocker dans l'état
      if (response.data.data && response.data.data.id) {
        setIdChangement(response.data.data.id);
        setNbreChangement(response.data.data.nbre_com);
        setPrixChangementSimple(response.data.data.montantSimple);
        setPrixChangementExpress(response.data.data.montantExpress);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //Enregistrement Mariage
  const handleAddMariage = async () => {
    const formData = new FormData();
    formData.append("langue", langueUtile);
    formData.append("type_service", selectService);
    formData.append("nom_epoux", nomEpouxMariage);
    formData.append("prenom_epoux", prenomEpouxMariage);
    formData.append("nom_epouse", nomEpouseMariage);
    formData.append("prenom_epouse", prenomEpouseMariage);
    formData.append("date_mariage", dateMariage);
    formData.append("nbre_com", nombreMariage.toString());
    // const donne = Object.fromEntries(formData);
    // console.log("DATA", donne);application/json; charset=UTF-8, multipart/form-data
    try {
      const response = await axios.post(urlProd + "/mariages/add", formData, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      setIsSubmited(true);

      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);
      // Accéder à l'ID de la donnée insérée si disponible et le stocker dans l'état
      if (response.data.data && response.data.data.id) {
        setIdMariage(response.data.data.id);
        setNbreMariage(response.data.data.nbre_com);
        setPrixMariageSimpleMG(response.data.data.montantSimpleMG);
        setPrixMariageSimpleFR(response.data.data.montantSimpleFR);
        setPrixMariageExpressMG(response.data.data.montantExpressMG);
        setPrixMariageExpressFR(response.data.data.montantExpressFR);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //Enregistrement Divorce
  const handleAddDivorce = async () => {
    const formData = new FormData();
    formData.append("langue", langueUtile);
    formData.append("type_service", selectService);
    formData.append("nom_epoux", nomEpouxDivorce);
    formData.append("prenom_epoux", prenomEpouxDivorce);
    formData.append("nom_epouse", nomEpouseDivorce);
    formData.append("prenom_epouse", prenomEpouseDivorce);
    formData.append("date_divorce", dateDivorce);
    formData.append("nbre_com", nombreDivorce.toString());
    try {
      const response = await axios.post(urlProd + "/divorces/add", formData, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      setIsSubmited(true);

      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);
      // Accéder à l'ID de la donnée insérée si disponible et le stocker dans l'état
      if (response.data.data && response.data.data.id) {
        setIdDivorce(response.data.data.id);
        setNbreDivorce(response.data.data.nbre_com);
        setPrixDivorceSimpleMG(response.data.data.montantSimpleMG);
        setPrixDivorceSimpleFR(response.data.data.montantSimpleFR);
        setPrixDivorceExpressMG(response.data.data.montantExpressMG);
        setPrixDivorceExpressFR(response.data.data.montantExpressFR);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //save Rectification
  const handleAddRectifiaction = async () => {
    const formData = new FormData();
    formData.append("type_service", selectService);
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("rectificat_acte", acteRectificat);
    formData.append("date_acte", dateRectificat);
    formData.append("nbre_com", nombreRectificat.toString());

    try {
      const response = await axios.post(
        urlProd + "/rectifications/add",
        formData,
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      setIsSubmited(true);

      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);
      // Accéder à l'ID de la donnée insérée si disponible et le stocker dans l'état
      if (response.data.data && response.data.data.id) {
        setIdRectification(response.data.data.id);
        setNbreRectification(response.data.data.nbre_com);
        setPrixRectificationSimple(response.data.data.montantSimple);
        setPrixRectificationExpress(response.data.data.montantExpress);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //Commande client
  const handleClickCommande = async () => {
    const sommeMontant = sommeCommande();
    const formData = new FormData();

    formData.append("date_commande", today);
    formData.append("ref_billet", randomReference.toString());
    formData.append("status", status);
    formData.append("fructueux", existe.toString());
    if (sommeMontant != undefined) {
      formData.append("montant", sommeMontant.toString());
    }
    formData.append("id_Client", idClient);

    if (idLegitimation != null && idLegitimation != "") {
      formData.append("id_Legitimation", idLegitimation);
    }

    if (idNaissance != null && idNaissance != "") {
      formData.append("id_Naiss", idNaissance);
    }
    if (idCelibat != null && idCelibat != "") {
      formData.append("id_Celibat", idCelibat);
    }
    if (idCoutume != null && idCoutume != "") {
      formData.append("id_Coutume", idCoutume);
    }
    if (idMatrimonial != null && idMatrimonial != "") {
      formData.append("id_Matri", idMatrimonial);
    }
    if (idBulletin != null && idBulletin != "") {
      formData.append("id_Bulletin", idBulletin);
    }
    if (idAdoption != null && idAdoption != "") {
      formData.append("id_Ado", idAdoption);
    }
    if (idChangement != null && idChangement != "") {
      formData.append("id_Changement", idChangement);
    }
    if (idExtrait != null && idExtrait != "") {
      formData.append("id_Extrait", idExtrait);
    }
    if (idMariage != null && idMariage != "") {
      formData.append("id_Mariage", idMariage);
    }
    if (idDivorce != null && idDivorce != "") {
      formData.append("id_Divorce", idDivorce);
    }
    if (idRectification != null && idRectification != "") {
      formData.append("id_Rectification", idRectification);
    }
    try {
      const response = await axios.post(urlProd + "/commandes/add", formData, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      setIsSubmited(true);
      // Afficher les données de la réponse dans la console
      // console.log("Données insérées avec succès :", response.data.message);

      if (response.data.data && response.data.data.id) {
        const idCom = response.data.data.id;
        setIdCommande(idCom);
        const montant = response.data.data.montant;
        const reference = response.data.data.ref_billet;
        const fructueux = response.data.data.fructueux;
        setDataMontant(montant);
        setDataRef(reference);
        setDataFructueux(fructueux);
      }
    } catch (error) {
      // console.error("Erreur lors de l'ajout de données :", error);
    }
  };

  //Somme de commande
  let somme = 0;
  const sommeCommande = () => {
    const montantNaissance = calculeMontant(
      serviceCommande,
      langueCommande,
      montantSimpleMG,
      montantExpressMG,
      montantSimpleFR,
      montantExpressFR,
      nbreNaissance
    );
    const montantAdoption = calculeMontant(
      serviceCommande,
      langueCommande,
      prixAdoptionSimpleMG,
      prixAdoptionExpressMG,
      prixAdoptionSimpleFR,
      prixAdoptionExpressFR,
      nbreAdoption
    );
    const montantBulletin = calculeMontantDetail(
      serviceCommande,
      nbreBulletin,
      prixBulletinSimple,
      prixBulletinExpress
    );
    const montantCoutume = calculeMontantDetail(
      serviceCommande,
      nbreCoutume,
      prixCoutumeSimple,
      prixCoutumeExpress
    );
    const montantCelibat = calculeMontantDetail(
      serviceCommande,
      nbreCelibat,
      prixCelibatSimple,
      prixCelibatExpress
    );
    const montantChangement = calculeMontantDetail(
      serviceCommande,
      nbreChangement,
      prixChangementSimple,
      prixChangementExpress
    );
    const montantDivorce = calculeMontant(
      serviceCommande,
      langueCommande,
      prixDivorceSimpleMG,
      prixDivorceExpressMG,
      prixDivorceSimpleFR,
      prixDivorceExpressFR,
      nbreDivorce
    );
    const montantExtrait = calculeMontantDetail(
      serviceCommande,
      nbreExtrait,
      prixExtraitSimple,
      prixExtraitExpress
    );
    const montantLegitimation = calculeMontant(
      serviceCommande,
      langueCommande,
      prixLegitimationSimpleMG,
      prixLegitimationExpressMG,
      prixLegitimationSimpleFR,
      prixLegitimationExpressFR,
      nbreLegitimation
    );
    const montantMariage = calculeMontant(
      serviceCommande,
      langueCommande,
      prixMariageSimpleMG,
      prixMariageExpressMG,
      prixMariageSimpleFR,
      prixMariageExpressFR,
      nbreMariage
    );
    const montantMatrimonal = calculeMontantDetail(
      serviceCommande,
      nbreMatrimonial,
      prixMatrimonialSimple,
      prixMatrimonialExpress
    );
    const montantRectification = calculeMontantDetail(
      serviceCommande,
      nbreRectification,
      prixRectificationSimple,
      prixRectificationExpress
    );
    // console.log(
    //   "valeur",
    //   montantNaissance,
    //   montantBulletin,
    //   montantCoutume,
    //   montantAdoption,
    //   montantCelibat,
    //   montantChangement,
    //   montantDivorce,
    //   montantExtrait,
    //   montantLegitimation,
    //   montantMariage,
    //   montantMatrimonal,
    //   montantRectification
    // );
    if (
      montantNaissance != undefined &&
      montantAdoption != undefined &&
      montantDivorce != undefined &&
      montantLegitimation != undefined &&
      montantMariage != undefined
    ) {
      somme +=
        montantNaissance +
        montantBulletin +
        montantCoutume +
        montantAdoption +
        montantCelibat +
        montantChangement +
        montantDivorce +
        montantExtrait +
        montantLegitimation +
        montantMariage +
        montantMatrimonal +
        montantRectification;
    }
    return somme;
  };
  function calculeMontantDetail(
    service: any,
    nombreCommande: any,
    prixSimple: any,
    prixExpress: any
  ) {
    if (service == "Standard") {
      return nombreCommande * prixSimple;
    } else {
      return nombreCommande * prixExpress;
    }
  }
  function calculeMontant(
    service: any,
    langue: any,
    prixSimpleMG: any,
    prixExpressMG: any,
    prixSimpleFR: any,
    prixExpressFR: any,
    nombreCommande: any
  ) {
    if (service == "Standard" && langue == "Malagasy") {
      return nombreCommande * prixSimpleMG;
    } else if (service == "Standard" && langue == "Français") {
      return nombreCommande * prixSimpleFR;
    } else if (service == "Express" && langue == "Malagasy") {
      return nombreCommande * prixExpressMG;
    } else if (service == "Express" && langue == "Français") {
      return nombreCommande * prixExpressFR;
    }
  }

  useEffect(() => {
    if (isSubmited) {
      setData(initialData);
      setNom("");
      setPrenom("");
      setDateNaiss("");
      setLieuNaiss("");
      setNumAct("");
      setNomCompletPapa("");
      setNomCompletMama("");
      setSelectedValue("");
      setSelectService("");
      setNombreCommande(0);
      setNombreCoutume(0);
      setNombreBulletin(0);
      setNombreCelibat(0);
      setNombreMatrimonial(0);
      setNombreLegitimation(0);
      setNombreAdoption(0);
      setNombreChangement(0);
      setNomCompletEpouxMariage("");
      setNomCompletEpouseMariage("");
      setDateMariage("");
      setNombreMariage(0);
      setNomCompletEpouxDivorce("");
      setNomCompletEpouseDivorce("");
      setDateDivorce("");
      setNombreDivorce(0);
      setNombreExtrait(0);
      setLangueUtile("");
      setDateAdoption("");
      setDateChangement("");
      setIsSubmited(false);
    }
  }, [isSubmited]);

  //Retourne vide
  const handleVide = () => {
    setData(initialData);
    setNom("");
    setPrenom("");
    setDateNaiss("");
    setLieuNaiss("");
    setNumAct("");
    setSelectService("");
    setNomCompletPapa("");
    setNomCompletMama("");
    setSelectedValue("");
    setNombreCommande(1);
    setNombreCoutume(1);
    setNombreBulletin(1);
    setNombreCelibat(1);
    setNombreMatrimonial(1);
    setNombreLegitimation(1);
    setNombreExtrait(1);
    setNombreAdoption(1);
    setNombreChangement(1);
    setNomCompletEpouxMariage("");
    setNomCompletEpouseMariage("");
    setDateMariage("");
    setNombreMariage(1);
    setNomCompletEpouxDivorce("");
    setNomCompletEpouseDivorce("");
    setDateDivorce("");
    setNombreDivorce(1);
    setLangueUtile("");
    setDateAdoption("");
    setDateChangement("");
  };

  //Naviguer vers Accueil
  const NavigateAccueil = () => {
    history.push("/");
  };

  const handleSubmit = async (e: any) => {
    navigateToFinish();
    e.preventDefault();
    await handleClick();
    if (displayCoutume == 1) {
      await handleAddCoutume();
    }
    if (displayMatri == 1) {
      await handleAddMatrimonial();
    }
    if (displayCelibat == 1) {
      await handleAddCelibat();
    }
    if (displayBulletin == 1) {
      await handleAddBulletin();
    }
    if (displayLegitimation == 1) {
      await handleAddLegitimation();
    }
    if (displayAdoption == 1) {
      await handleAddAdoption();
    }
    if (displayChange == 1) {
      await handleAddChangementNom();
    }
    if (displayExtrait == 1) {
      await handleAddExtrait();
    }
    if (displayMariage == 1) {
      await handleAddMariage();
    }
    if (displayDivorce == 1) {
      await handleAddDivorce();
    }
    if (displayRectificat == 1) {
      await handleAddRectifiaction();
    }
  };

  useEffect(() => {
    if (
      (seconde == 0 || minute == 0) &&
      idCommande != 0 &&
      idCommande != null
    ) {
      const UtilisateurCopie = async () => {
        const val = await fetch(urlProd + `/commandes/${idCommande}`);
        const data = await val.json();
        if (data.commande.fructueux != "" || data.commande.fructueux != null) {
          setDataFructueux(data.commande.fructueux);
        }
      };
      UtilisateurCopie();
    }
  }, [seconde, minute, idCommande]);

  return (
    <>
      <Navbar titreImg="/icon/logo.png" />
      <div className="pt-4 px-3">
        <h2 style={{ textAlign: "center", fontFamily: " Georgia, serif" }}>
          Demande de l'acte de naissance{" "}
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="row mt-2">
            <div className="row">
              <div className="col-sm">
                <RadioButton
                  nameLabel="Langue"
                  radioValue1="Malagasy"
                  handleChangeRadio1={handleChangeLangue}
                  radioLabel1="Malagasy"
                  radioValue2="Français"
                  handleChangeRadio2={handleChangeLangue}
                  radioLabel2="Français"
                />
              </div>
              <div className="col-sm">
                <RadioButtonTerme
                  nameLabel="Sigle"
                  radioValue1="NaissDelai"
                  handleChangeRadio1={handleChangeSigne}
                  radioLabel1="Naissance/délai"
                  radioValue2="JugSuppl"
                  handleChangeRadio2={handleChangeSigne}
                  radioLabel2="Jug Suppl"
                  radioValue3="reconstit"
                  handleChangeRadio3={handleChangeSigne}
                  radioLabel3="Reconstitution"
                />
              </div>
            </div>
            <div className="col-sm-3">
              <SelectService
                label="Service :"
                nameSelect="Choisissez votre service"
                name1="Service Standard"
                name2="Service Express"
                selectValue={selectService}
                handleSelected={handleChangeService}
              />
              <Input
                label="Nom :"
                type="text"
                name="nom"
                value={nom}
                handleChange={handleChangeNom}
                required="required"
              />
              <Input
                label="Prénom(s) :"
                name="prenom"
                value={prenom}
                handleChange={handleChangePrenom}
              />

              <Input
                label="Nom et Prénom de père :"
                name="nom_pere"
                value={nomCompletPapa}
                handleChange={handleChangeNomCompletPere}
              />

              <Input
                label="Nom et Prénom de mère :"
                name="nom_mere"
                value={nomCompletMama}
                handleChange={handleChangeNomCompletMere}
              />
            </div>
            <div className="col-sm-3">
              <SelectLieu
                label="Lieu de naissance :"
                nameSelect="Choisissez votre lieu de naissance"
                name1="Mahajanga"
                name2="Mahabibo"
                selectValue={lieuNaiss}
                handleSelected={handleChangeLieu}
              />
              <Input
                label="Date de naissance :"
                name="ddn"
                type="date"
                value={dateNaiss}
                handleChange={handleChangeNaiss}
                required="required"
              />

              <Input
                label="Numéro de l'acte :"
                name="numAct"
                value={numAct}
                handleChange={handleChangeNumActe}
              />

              <Input
                label="Nombre de commande :"
                type="number"
                min={1}
                value={nombreCommande}
                handleChange={handleChangeNbreCommande}
                name="nbCommande"
                required="required"
              />
            </div>

            <>
              <div className="col-sm-6">
                <h4 className="center">Commandes complémentaires</h4>
                <div className="row">
                  <div className="col-sm-4">
                    <CheckBox name="Coutume" onClick={showHideCoutume} />
                    <div
                      id="coutume"
                      style={{
                        display: displayCoutume === 1 ? "block" : "none",
                      }}
                    >
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Nombre de commande:"
                            type="number"
                            min={1}
                            value={nombreCoutume}
                            handleChange={handleChangeNbreCoutume}
                            name="nbCoutume"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <CheckBox
                      name="Matrimonial"
                      onClick={showHideMatrimonial}
                    />
                    <div
                      id="Matrimonial"
                      style={{
                        display: displayMatri === 1 ? "block" : "none",
                      }}
                    >
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Nombre de commande:"
                            type="number"
                            min={1}
                            value={nombreMatrimonial}
                            handleChange={handleChangeNbreMatrimonial}
                            name="nbMatrimnial"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <CheckBox name="Célibat" onClick={showHideCelibat} />
                    <div
                      id="celibat"
                      style={{
                        display: displayCelibat === 1 ? "block" : "none",
                      }}
                    >
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Nombre de commande:"
                            type="number"
                            min={1}
                            value={nombreCelibat}
                            handleChange={handleChangeNbreCelibat}
                            name="nbCelibat"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-sm-4">
                    <CheckBox
                      name="Bulletin de naissance"
                      onClick={showHideBulletin}
                    />
                    <div
                      id="Bulletin"
                      style={{
                        display: displayBulletin === 1 ? "block" : "none",
                      }}
                    >
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Nombre de commande:"
                            type="number"
                            min={1}
                            value={nombreBulletin}
                            handleChange={handleChangeNbreBulletin}
                            name="nbBulletin"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <CheckBox
                      name="Extrait de naissance"
                      onClick={showHidExtrait}
                    />
                    <div
                      id="extrait"
                      style={{
                        display: displayExtrait === 1 ? "block" : "none",
                      }}
                    >
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Nombre de commande:"
                            type="number"
                            min={1}
                            value={nombreExtrait}
                            handleChange={handleChangeNbreExtrait}
                            name="nbExtrait"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <CheckBox
                      name="Légitimation"
                      onClick={showHideLegitimation}
                    />
                    <div
                      id="legitimation"
                      style={{
                        display: displayLegitimation === 1 ? "block" : "none",
                      }}
                    >
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Nombre de commande:"
                            type="number"
                            min={1}
                            value={nombreLegitimation}
                            handleChange={handleChangeNbreLegitimation}
                            name="nbLegitimation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-sm-4">
                    <CheckBox
                      name="Rectification"
                      onClick={showHideRectificat}
                    />
                    <div
                      id="rectification"
                      style={{
                        display: displayRectificat === 1 ? "block" : "none",
                      }}
                    >
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Date de l'acte:"
                            type="date"
                            name="ddRectificat"
                            value={dateRectificat}
                            handleChange={handleChangeDateRectificat}
                          />
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Nombre de commande:"
                            type="number"
                            min={1}
                            value={nombreRectificat}
                            handleChange={handleChangeNbreRectificat}
                            name="nbRectificat"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <CheckBox name="Adoption" onClick={showHideAdoption} />
                    <div
                      id="adoption"
                      style={{
                        display: displayAdoption === 1 ? "block" : "none",
                      }}
                    >
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Date de l'acte:"
                            type="date"
                            name="ddadoption"
                            value={dateAdoption}
                            handleChange={handleChangeDateAdoption}
                          />
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Nombre de commande:"
                            type="number"
                            min={1}
                            value={nombreAdoption}
                            handleChange={handleChangeNbreAdoption}
                            name="nbadoption"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <CheckBox
                      name="Changement de nom "
                      onClick={showHideChangement}
                    />
                    <div
                      id="Changement"
                      style={{
                        display: displayChange === 1 ? "block" : "none",
                      }}
                    >
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Date de l'acte:"
                            type="date"
                            name="ddchangement"
                            value={dateChangement}
                            handleChange={handleChangeDateChangement}
                          />
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Nombre de commande:"
                            type="number"
                            min={1}
                            value={nombreChangement}
                            handleChange={handleChangeNbreChangement}
                            name="nbChangement"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <CheckBox name="Mariage" onClick={showHideMariage} />
                    <div
                      id="mariage"
                      style={{
                        display: displayMariage === 1 ? "block" : "none",
                      }}
                    >
                      {/* <div className="row">
                        <div className="col-sm">
                          <RadioButton1
                            nameLabel="Langue"
                            radioValue1="Malagasy"
                            // handleChangeRadio1={handleChangeLangue}
                            radioLabel1="Malagasy"
                            radioValue2="Français"
                            // handleChangeRadio2={handleChangeLangue}
                            radioLabel2="Français"
                          />
                        </div>
                      </div> */}
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Nom et Prénom de l'époux"
                            type="text"
                            name="nom_prenomEpoux"
                            value={nomCompletEpouxMariage}
                            handleChange={handleChangeNomEpouxMariage}
                          />
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Nom et Prénom de l'épouse"
                            type="text"
                            name="nomPrenomEpouse"
                            value={nomCompletEpouseMariage}
                            handleChange={handleChangeNomEpouseMariage}
                          />
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Date du mariage:"
                            type="date"
                            name="ddMariage"
                            value={dateMariage}
                            handleChange={handleChangeDateMariage}
                          />
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Nombre de commande:"
                            type="number"
                            min={1}
                            value={nombreMariage}
                            handleChange={handleChangeNbreMariage}
                            name="nbMariage"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <CheckBox name="Divorce" onClick={showHideDivorce} />
                    <div
                      id="Divorce"
                      style={{
                        display: displayDivorce === 1 ? "block" : "none",
                      }}
                    >
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Nom et Prénom de l'époux"
                            type="text"
                            name="nom_prenomEpouxDivorce"
                            value={nomCompletEpouxDivorce}
                            handleChange={handleChangeNomEpouxDivorce}
                          />
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Nom et Prénom de l'épouse"
                            type="text"
                            name="nomPrenomEpouseDivorce"
                            value={nomCompletEpouseDivorce}
                            handleChange={handleChangeNomEpouseDivorce}
                          />
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Date du divorce:"
                            type="date"
                            name="ddDivorce"
                            value={dateDivorce}
                            handleChange={handleChangeDateDivorce}
                          />
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Nombre de commande:"
                            type="number"
                            min={1}
                            value={nombreDivorce}
                            handleChange={handleChangeNbreDivorce}
                            name="nbDivorce"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-4">
                    <CheckBox
                      name="Reconnaissance"
                      onClick={showHideReconnaissance}
                    />
                    <div
                      id="Reconnaissance"
                      style={{
                        display: displayReconnaissance === 1 ? "block" : "none",
                      }}
                    >
                      <div className="row ">
                        <div className="col-sm">
                          <Input
                            label="Nombre de commande:"
                            type="number"
                            min={1}
                            // value={nombreCommande}
                            //handleChange={handleChangeNbreCommande}
                            name="nbReconnaissance"
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div className="row">
                  <div className="col-sm">
                    <div className="row justify-content-center pt-2">
                      <button
                        type="button"
                        onClick={handleVide}
                        className="col-sm-6 btn btn-sm btn-primary mt-4 "
                        style={{
                          display:
                            idNaissance == "" || idNaissance == null
                              ? "block"
                              : "none",
                        }}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="row justify-content-center pt-2">
                      <button
                        type="submit"
                        // onClick={tempRestant}
                        className="col-sm-6 btn btn-sm btn-primary mt-4"
                        style={{
                          display:
                            idNaissance == "" || idNaissance == null
                              ? "block"
                              : "none",
                        }}
                      >
                        Passer à la commande
                      </button>
                    </div>
                  </div>
                </div>
                {idNaissance != null && idNaissance != "" ? (
                  <div className="row">
                    <div
                      className="col-sm-11 pt-2 mt-4 pb-2 "
                      style={{
                        borderRadius: "10px",
                        border: "1px solid #dee2e6 ",
                        // height: "170px",
                      }}
                    >
                      <h4
                        className="center"
                        style={{
                          fontFamily: " Georgia, serif",
                          textAlign: "center",
                        }}
                      >
                        Information sur votre commande :
                      </h4>
                      <div
                        style={{
                          fontFamily: " Georgia, serif",
                          marginLeft: "30px",
                        }}
                      >
                        <h6 className="col-sm-12 pt-2">
                          Nom : {dataNomEnfant}
                        </h6>
                        <h6 className="col-sm pt-2">
                          Prénom : {dataPrenomEnfant}
                        </h6>
                        <h6 className="col-sm pt-2">
                          Langue : {langueCommande}
                        </h6>
                        <h6 className="col-sm pt-2">
                          Type de service : {dataService}
                        </h6>
                      </div>
                      <div className="row">
                        <div className="col-sm"></div>
                        <div className="col-sm">
                          <div className="row justify-content-center pt-2">
                            <button
                              type="button"
                              onClick={() => {
                                handleClickCommande();
                                toggleModal();
                              }}
                              style={{
                                display:
                                  idClient == "" || idClient == null
                                    ? "none"
                                    : "block",
                              }}
                              className="col-sm-6 btn btn-sm btn-primary mt-4"
                            >
                              Commander
                            </button>
                            <Modal
                              title="Commande réussi !"
                              isOpen={isModal}
                              onClose={() => {
                                toggleModal();
                                NavigateAccueil();
                              }}
                            >
                              <hr />
                              <h5
                                style={{
                                  fontFamily: " Georgia, serif",
                                }}
                              >
                                Détails de votre commande :
                              </h5>

                              <div className="row">
                                <div className="col-sm-5">
                                  {" "}
                                  Acte de naissance :<b> {nbreNaissance} </b>
                                </div>
                                <div className="col-sm-3">
                                  {" "}
                                  {idCoutume != null && idCoutume != "" ? (
                                    <>
                                      Coutume : <b> {nbreCoutume} </b>
                                    </>
                                  ) : (
                                    <>
                                      Coutume : <b> 0 </b>
                                    </>
                                  )}
                                </div>{" "}
                                <div className="col-sm-4">
                                  {idMatrimonial != null &&
                                  idMatrimonial != "" ? (
                                    <>
                                      Matrimonial : <b> {nbreMatrimonial} </b>
                                    </>
                                  ) : (
                                    <>
                                      Matrimonial : <b> 0 </b>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-5">
                                  {idChangement != null &&
                                  idChangement != "" ? (
                                    <>
                                      Changement de nom :
                                      <b> {nbreChangement} </b>
                                    </>
                                  ) : (
                                    <>
                                      Changement de nom :<b> 0 </b>
                                    </>
                                  )}
                                </div>
                                <div className="col-sm-3">
                                  {idBulletin != null && idBulletin != "" ? (
                                    <>
                                      Bulletin : <b> {nbreBulletin} </b>
                                    </>
                                  ) : (
                                    <>
                                      Bulletin : <b> 0 </b>
                                    </>
                                  )}
                                </div>
                                <div className="col-sm-4">
                                  {idLegitimation != null &&
                                  idLegitimation != "" ? (
                                    <>
                                      Legitimation :<b> {nbreLegitimation} </b>
                                    </>
                                  ) : (
                                    <>
                                      Legitimation :<b> 0 </b>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-5">
                                  {idExtrait != null && idExtrait != "" ? (
                                    <>
                                      Extrait de naissances :
                                      <b> {nbreExtrait} </b>
                                    </>
                                  ) : (
                                    <>
                                      Extrait de naissances :<b> 0 </b>
                                    </>
                                  )}
                                </div>
                                <div className="col-sm-3">
                                  {idAdoption != null && idAdoption != "" ? (
                                    <>
                                      Adoption : <b> {nbreAdoption} </b>
                                    </>
                                  ) : (
                                    <>
                                      Adoption : <b> 0 </b>
                                    </>
                                  )}
                                </div>
                                <div className="col-sm-4">
                                  {idMariage != null && idMariage != "" ? (
                                    <>
                                      Mariage : <b> {nbreMariage} </b>
                                    </>
                                  ) : (
                                    <>
                                      Mariage : <b> 0 </b>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-5">
                                  {idRectification != null &&
                                  idRectification != "" ? (
                                    <>
                                      Rectification :{" "}
                                      <b> {nbreRectification} </b>
                                    </>
                                  ) : (
                                    <>
                                      Rectification : <b> 0 </b>
                                    </>
                                  )}
                                </div>
                                <div className="col-sm-3">
                                  {idDivorce != null && idDivorce != "" ? (
                                    <>
                                      Divorce : <b>{nbreDivorce}</b>
                                    </>
                                  ) : (
                                    <>
                                      Divorce : <b> 0 </b>
                                    </>
                                  )}
                                </div>
                                <div className="col-sm-4">
                                  {idCelibat != null && idCelibat != "" ? (
                                    <>
                                      Celibat : <b> {nbreCelibat} </b>
                                    </>
                                  ) : (
                                    <>
                                      Celibat : <b> 0 </b>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div>
                                <h6 className="col-sm pt-2">
                                  Reférence : NCE-{dataRef}
                                  <br />
                                  Net à payer : {dataMontant} Ar
                                  <br />
                                </h6>
                              </div>
                              <hr />

                              <div className="row justify-content-center pt-2">
                                <div className="timer">
                                  <div className="container">
                                    <div className="timer_container">
                                      <div style={{ textAlign: "center" }}>
                                        <span
                                          style={{
                                            fontFamily: " Georgia, serif",
                                            fontSize: "14px",
                                          }}
                                        >
                                          Veuillez patienter après quelques
                                          minutes pour obtenir votre réponse ...
                                        </span>
                                        <br />
                                        <span style={{ fontSize: "20px" }}>
                                          Temps restant:
                                        </span>{" "}
                                        {minute}:{seconde}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {minute == 0 && seconde == 0 ? (
                                  <>
                                    {dataFructueux == 1 ? (
                                      <span style={{ textAlign: "center" }}>
                                        Passez prendre votre commande :
                                        {dataService == "Express" ? (
                                          <span>&nbsp;&nbsp;Après 1 heure</span>
                                        ) : (
                                          <span>
                                            {" "}
                                            &nbsp;&nbsp;Après 2 jours
                                          </span>
                                        )}
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          fontFamily: " Georgia, serif",
                                          fontSize: "16px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Veuillez contacter direct au responsable
                                        à l'hôtel de ville
                                      </span>
                                    )}
                                  </>
                                ) : null}
                              </div>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          </div>
        </form>
      </div>
    </>
  );
};

const CheckBox = ({ name, checked, onClick }: any) => {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        value=""
        checked={checked}
        onClick={onClick}
        id="flexCheckDefault"
      />
      <label className="form-check-label" htmlFor="flexCheckDefault">
        {name}
      </label>
    </div>
  );
};
const Input = ({
  label,
  type = "text",
  min = 1,
  name,
  handleChangefile,
  handleChange,
  accept,
  disable,
  value,
  style,
  checked,
  required,
}: any) => {
  return (
    <div className="form">
      <label htmlFor="" className="form-label">
        {label}
      </label>
      <>
        {type == "file" ? (
          <input
            accept="image/*"
            onChange={handleChangefile}
            name={name}
            type={type}
            min={min}
            className="form-control"
            required={required}
          />
        ) : (
          <input
            onChange={handleChange}
            name={name}
            type={type}
            value={value}
            disabled={disable}
            checked={checked}
            min={min}
            className="form-control"
            style={style}
            required={required}
          />
        )}
      </>
    </div>
  );
};

const SelectService = ({
  label,
  nameSelect,
  name,
  name1,
  name2,
  selectValue,
  handleSelected,
  id,
}: any) => {
  return (
    <>
      <label htmlFor="">{label}</label>
      <select
        className="form-select mb-2"
        aria-label="Default select example"
        name={name}
        value={selectValue}
        onChange={handleSelected}
        required
      >
        <option value="" key={id}>
          {nameSelect}
        </option>
        <option value="Standard">{name1}</option>
        <option value="Express">{name2}</option>
      </select>
    </>
  );
};
const SelectLieu = ({
  label,
  nameSelect,
  name,
  name1,
  name2,
  selectValue,
  handleSelected,
  id,
}: any) => {
  return (
    <>
      <label htmlFor="">{label}</label>
      <select
        className="form-select mb-2"
        aria-label="Default select example"
        name={name}
        value={selectValue}
        onChange={handleSelected}
        required
      >
        <option value="" key={id}>
          {nameSelect}
        </option>
        <option value="Mahajanga">{name1}</option>
        <option value="Mahabibo">{name2}</option>
      </select>
    </>
  );
};
function RadioButton1({
  nameLabel,
  radioLabel1,
  radioLabel2,
  radioValue1,
  radioValue2,
  handleChangeRadio1,
  handleChangeRadio2,
}: any) {
  return (
    <div className="row">
      <div className="col-sm">
        <label className="label text-gray-1000">{nameLabel} : &nbsp;</label>
        <div className="form-check-inline">
          <input
            type="radio"
            className="form-check-input"
            value={radioValue1}
            name="group1"
            id="malagasyRadio"
            onChange={handleChangeRadio1}
            required
          />
          <label className="form-check-label">{radioLabel1}</label>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div className="form-check-inline validate-input mb-2">
          <input
            type="radio"
            className="form-check-input"
            value={radioValue2}
            name="group1"
            id="francaisRadio"
            onChange={handleChangeRadio2}
          />
          <label className="form-check-label">{radioLabel2}</label>
        </div>
      </div>
    </div>
  );
}
function RadioButton({
  nameLabel,
  radioLabel1,
  radioLabel2,
  radioValue1,
  radioValue2,
  handleChangeRadio1,
  handleChangeRadio2,
}: any) {
  return (
    <div className="row">
      <div className="col-sm ">
        <label className="label text-gray-1000">{nameLabel} : &nbsp;</label>
        <div className="form-check-inline">
          <input
            type="radio"
            className="form-check-input"
            value={radioValue1}
            name="groupe2"
            id="malagasyRadio"
            onChange={handleChangeRadio1}
            required
          />
          <label className="form-check-label">{radioLabel1}</label>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div className="form-check-inline validate-input mb-2">
          <input
            type="radio"
            className="form-check-input"
            value={radioValue2}
            name="groupe2"
            id="francaisRadio"
            onChange={handleChangeRadio2}
          />
          <label className="form-check-label">{radioLabel2}</label>
        </div>
      </div>
    </div>
  );
}
function RadioButtonTerme({
  nameLabel,
  radioLabel1,
  radioLabel2,
  radioLabel3,
  radioValue1,
  radioValue2,
  radioValue3,
  handleChangeRadio1,
  handleChangeRadio2,
  handleChangeRadio3,
}: any) {
  return (
    <div className="row">
      <div className="col-sm">
        <label className="label text-gray-1000">{nameLabel} : &nbsp;</label>
        <div className="form-check-inline">
          <input
            type="radio"
            className="form-check-input"
            value={radioValue1}
            name="type[]"
            id="NaissDelai"
            onChange={handleChangeRadio1}
            required
          />
          <label className="form-check-label">{radioLabel1}</label>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div className="form-check-inline validate-input mb-2">
          <input
            type="radio"
            className="form-check-input"
            value={radioValue2}
            name="type[]"
            id="JugSuppl"
            onChange={handleChangeRadio2}
          />
          <label className="form-check-label">{radioLabel2}</label>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div className="form-check-inline validate-input mb-2">
          <input
            type="radio"
            className="form-check-input"
            value={radioValue3}
            name="type[]"
            id="JugSupp"
            onChange={handleChangeRadio3}
          />
          <label className="form-check-label">{radioLabel3}</label>
        </div>
      </div>
    </div>
  );
}
