import * as Icon from "react-feather";
import "./style.css";

export const Navbar = ({ titreImg, prop, className }: any) => {
  return (
    <nav
      className={
        "navbar navbar-dark bg-dark flex-md-nowrap p-0 shadow " + className
      }
    >
      <div className="container-fluid">
        <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3">
          <img
            className="mx-3"
            // src="icon/logo.png"
            src={titreImg}
            alt=""
            width="70"
            height="50"
          />
        </a>
      </div>
    </nav>
  );
};
